define("innowaze-ember2/routes/ideas/new", ["exports", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/routes/ideas/common"], function (_exports, _setCurrentChallenge, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend(_setCurrentChallenge.default, {
    queryParams: {
      domain: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        idea: this.store.createRecord("idea", {
          challenge_id: params.challenge_id,
          description: "",
          completion: "0",
          stakes: 0,
          accessibility: 0,
          maturity: 0,
          strategy: 0,
          documents: [],
          tags: [],
          teammates: [],
          domains: [],
          extra_modules: [],
          extra_tools: [],
          land_production_type: [],
          index_stage: 0
        })
      });
    },
    afterModel: function afterModel(_ref, _ref2) {
      var challenge = _ref.challenge,
          idea = _ref.idea;
      var queryParams = _ref2.queryParams;

      this._super.apply(this, arguments);

      var domainId = queryParams.domain;

      if (!domainId) {
        return;
      }

      var domain = this.store.peekRecord("Domain", domainId);

      if (domain && domain.get("challenge_id") === parseInt(challenge.id)) {
        idea.set("domains", [domain]);
      }
    },
    controllerName: "ideas/edit",
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set("notRelatedIdeas", []);

      if (transition.queryParams.comment) {
        var comment = this.store.peekRecord("comment", transition.queryParams.comment);

        if (comment) {
          var commentContent = comment.get("content");
          model.idea.set("comment_id", comment.get("id"));
          model.idea.set("user_id", comment.get("user_id"));
          model.idea.set("description", commentContent.replace(/(\r\n|\n|\r)/gm, "<br>"));
          var firstSentence = commentContent.split("\n").shift();

          if (firstSentence && firstSentence.length >= 60) {
            model.idea.set("name", firstSentence.substr(0, 60) + "...");
          } else {
            model.idea.set("name", firstSentence);
          }
        }
      }

      if (transition.queryParams.masterId) {
        var master = this.store.peekRecord("idea", transition.queryParams.masterId);

        if (master) {
          model.idea.set("master", master);
        }
      }

      controller.setProperties({
        routeIsReadyForAction: true,
        evaluateOnUpdate: true
      });
    },
    resetController: function resetController(controller) {
      controller.set("routeIsReadyForAction", false);
      controller.set("domain", null);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.controller.set("saveDisabled", true);
        this.currentModel.idea.save({
          adapterOptions: {
            challenge_id: this.currentModel.idea.get("challenge_id")
          }
        }).then(function (data) {
          return _this._success(data.id);
        }, function (errors) {
          return _this._error(errors);
        });
      },
      cancel: function cancel() {
        this.transitionTo("ideas", this.currentModel.challenge.id);
      }
    },
    _success: function _success(id) {
      this.controller.set("saveDisabled", false);
      this.notifications.success(this.locale.t("ideas.updated_idea"));
      this.set("controller.userHasSaved", true);
      this.transitionTo("ideas.show", this.currentModel.challenge.id, id);
    },
    _error: function _error(_ref3) {
      var errors = _ref3.errors;
      this.controller.set("saveDisabled", false);
      this.set("controller.userHasSaved", false);
      this.notifications.errorWithList(this.locale.t("creation_error"), errors);
    }
  });

  _exports.default = _default;
});