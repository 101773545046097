define("innowaze-ember2/components/modules/savings-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    manualRealCost: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get("model.real_cost") && this.get("model.real_cost") !== this.computedRealCost) {
        this.set("manualRealCost", true);
      }
    },
    completedDidChange: Ember.on("init", Ember.observer("model.savings", function () {
      this.set("module.isNotCompleted", !this.get("model.savings"));
    })),
    realCostMustChange: Ember.on("init", Ember.observer("computedRealCost", function () {
      if (this.manualRealCost) {
        return;
      }

      this.set("model.real_cost", this.computedRealCost);
    })),
    computedRealCost: Ember.computed("model.savings", "model.funding", function () {
      if (!this.get("model.savings") || !this.get("model.funding")) {
        return null;
      }

      return Math.ceil(this.get("model.savings") * (1 - this.get("model.funding") / 100));
    }),
    actions: {
      handleRealCostFocusOut: function handleRealCostFocusOut() {
        if (this.get("model.real_cost") && this.get("model.real_cost") !== this.computedRealCost) {
          this.set("manualRealCost", true);
        }

        if (!this.get("model.real_cost")) {
          this.set("model.real_cost", this.computedRealCost);
          this.set("manualRealCost", false);
        }
      }
    }
  });

  _exports.default = _default;
});