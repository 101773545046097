define("innowaze-ember2/models/idea", ["exports", "ember-data", "innowaze-ember2/mixins/models/commentHandler", "innowaze-ember2/models/application"], function (_exports, _emberData, _commentHandler, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_commentHandler.default, {
    localeService: Ember.inject.service("locale"),
    accessibility_is_median: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    accessibility: _emberData.default.attr("number"),
    address: _emberData.default.attr("string"),
    administrative_area_level_1: _emberData.default.attr(),
    administrative_area_level_2: _emberData.default.attr(),
    administrative_area_level_3: _emberData.default.attr(),
    administrative_area_level_4: _emberData.default.attr(),
    administrative_area_level_5: _emberData.default.attr(),
    all_domains: _emberData.default.attr(),
    all_states: _emberData.default.attr(),
    author_id: _emberData.default.attr("string"),
    atom: _emberData.default.attr(),
    author_departments: _emberData.default.attr(),
    author_entity_name: _emberData.default.attr("string"),
    author_site: _emberData.default.attr(),
    author: _emberData.default.belongsTo("user", {
      async: false
    }),
    author_name: _emberData.default.attr("string"),
    available_target_domains: _emberData.default.attr(),
    avatar_url: _emberData.default.attr("string"),
    awards_year: _emberData.default.attr("number"),
    awards_category: _emberData.default.attr("string"),
    benefits_type: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    benefitsTypeIsCsr: _emberData.default.attr("boolean"),
    benefitsTypeIsOther: _emberData.default.attr("boolean"),
    benefitsTypeIsSavings: _emberData.default.attr("boolean"),
    benefitsTypeIsTurnover: _emberData.default.attr("boolean"),
    best_evaluations: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    budget: _emberData.default.attr("number"),
    by_level1_expert: _emberData.default.attr("string"),
    by_level2_expert: _emberData.default.attr("string"),
    by_security_expert: _emberData.default.attr("string"),
    canEdit: _emberData.default.attr("boolean"),
    challenge_id: _emberData.default.attr("number"),
    checkbox_type: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    checkbox_explanation: _emberData.default.attr(),
    children: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    city: _emberData.default.attr(),
    color: _emberData.default.attr(),
    color_style: _emberData.default.attr(),
    comments: _emberData.default.hasMany("comment", {
      async: false
    }),
    commentsCount: _emberData.default.attr(),
    completion: _emberData.default.attr(),
    contributors: _emberData.default.hasMany("user", {
      async: false
    }),
    country: _emberData.default.attr(),
    created_at: _emberData.default.attr(),
    csr_explanation: _emberData.default.attr("string"),
    current_user_accessibility_evaluated: _emberData.default.attr(),
    current_user_accessibility: _emberData.default.attr(),
    current_user_evaluation_completed: _emberData.default.attr(),
    current_user_evaluation_description: _emberData.default.attr("string"),
    current_user_extra_tools: _emberData.default.attr(),
    current_user_given_points: _emberData.default.attr(),
    current_user_maturity_evaluated: _emberData.default.attr(),
    current_user_maturity: _emberData.default.attr(),
    current_user_rating: _emberData.default.attr(),
    current_user_stakes_evaluated: _emberData.default.attr(),
    current_user_stakes: _emberData.default.attr(),
    current_user_strategy_evaluated: _emberData.default.attr(),
    current_user_strategy: _emberData.default.attr(),
    department: _emberData.default.belongsTo("department"),
    deployment_date: _emberData.default.attr(),
    description: _emberData.default.attr(),
    documents: _emberData.default.attr(),
    domains: _emberData.default.attr(),
    domain_ids: _emberData.default.attr(),
    evaluation_description: _emberData.default.attr("string"),
    extra_modules: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    extra_tools: _emberData.default.attr(),
    first_domain: _emberData.default.attr("string"),
    funding: _emberData.default.attr("number"),
    followed: _emberData.default.attr("boolean"),
    generalization: _emberData.default.attr("boolean"),
    generalization_explanation: _emberData.default.attr(),
    hasAddress: _emberData.default.attr(),
    has_synchronization: _emberData.default.attr("boolean"),
    idea_explanation: _emberData.default.attr(),
    image_url: _emberData.default.attr(),
    index_stage: _emberData.default.attr(),
    innovation_comment: _emberData.default.attr("string"),
    innovation_index: _emberData.default.attr("number"),
    investments: _emberData.default.attr("number"),
    isAuthor: _emberData.default.attr("boolean"),
    is_budget: _emberData.default.attr("boolean"),
    is_primary_expert: _emberData.default.attr("boolean"),
    is_secondary_expert: _emberData.default.attr("boolean"),
    is_level2_expert: _emberData.default.attr("boolean"),
    is_security_expert: _emberData.default.attr("boolean"),
    canValidate: _emberData.default.attr("boolean"),
    keywords: _emberData.default.attr(),
    knowledges: _emberData.default.attr(),
    land_access: _emberData.default.attr(),
    land_activity: _emberData.default.attr(),
    land_administrator: _emberData.default.attr(),
    land_environment: _emberData.default.attr(),
    land_other_breeding: _emberData.default.attr(),
    land_other_production: _emberData.default.attr(),
    land_owner: _emberData.default.attr(),
    land_patches: _emberData.default.attr(),
    land_production_type: _emberData.default.attr(),
    land_security: _emberData.default.attr(),
    land_surface: _emberData.default.attr(),
    land_water_point: _emberData.default.attr(),
    lat: _emberData.default.attr(),
    level1_expert_validation: _emberData.default.attr("number"),
    level1_validation_date: _emberData.default.attr(),
    level2_expert_name: _emberData.default.attr("string"),
    level2_expert_validation: _emberData.default.attr("number"),
    level2_validation_date: _emberData.default.attr(),
    likes: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    likes_intra_site: _emberData.default.attr(),
    likesCount: _emberData.default.attr(),
    likeValue: _emberData.default.attr(),
    lng: _emberData.default.attr(),
    location_name: _emberData.default.attr(),
    logo_id: _emberData.default.attr(),
    logo_url: _emberData.default.attr(),
    main_lever: _emberData.default.attr("string"),
    master: _emberData.default.attr(),
    maturity: _emberData.default.attr(),
    maturity_is_median: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    meteo: _emberData.default.attr(),
    methodology: _emberData.default.attr("string"),
    methodology_comment: _emberData.default.attr("string"),
    mirror_challenge_id: _emberData.default.attr("string"),
    mirror_challenge_name: _emberData.default.attr(),
    mirror_idea: _emberData.default.attr(),
    mirror_target_domain: _emberData.default.attr(),
    molecule: _emberData.default.attr(),
    name: _emberData.default.attr(),
    new_comments_count: _emberData.default.attr(),
    new_department: _emberData.default.attr(),
    author_department_id: _emberData.default.attr("number"),
    parents: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    no_concept: _emberData.default.attr("boolean"),
    other_benefits_explanation: _emberData.default.attr("string"),
    owner_scope: _emberData.default.belongsTo("Scope", {
      async: false
    }),
    parent_ids: _emberData.default.attr(),
    past_future_comment: _emberData.default.attr("string"),
    partnership_type: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    percent_intra_site: _emberData.default.attr(),
    previous_state: _emberData.default.attr(),
    primary_expert_name: _emberData.default.attr("string"),
    private: _emberData.default.attr(),
    product_line: _emberData.default.attr("string"),
    project_stage: _emberData.default.attr("string"),
    published_at: _emberData.default.attr(),
    purchasing_type: _emberData.default.attr("string"),
    purchasing_family_id: _emberData.default.attr("string"),
    purchasing_contacts: _emberData.default.attr(),
    real_cost: _emberData.default.attr("number"),
    remark: _emberData.default.attr(),
    replicas: _emberData.default.attr(),
    replicationsCount: _emberData.default.attr("number"),
    replication_status: _emberData.default.attr(),
    replicators: _emberData.default.hasMany("user", {
      async: false
    }),
    reported_in_smart: _emberData.default.attr(),
    risk_analysis: _emberData.default.attr("string"),
    roi: _emberData.default.attr("number"),
    root: _emberData.default.attr(),
    saving_records: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    savings: _emberData.default.attr("number"),
    savings_explanation: _emberData.default.attr("string"),
    scale: _emberData.default.attr(),
    scopes: _emberData.default.hasMany("scope", {
      async: false
    }),
    secondary_expert_name: _emberData.default.attr("string"),
    security_expert_name: _emberData.default.attr("string"),
    security_expert_validation: _emberData.default.attr("number"),
    security_validation_date: _emberData.default.attr(),
    selectors: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    slide_url: _emberData.default.attr(),
    smart_number: _emberData.default.attr(),
    solution_id: _emberData.default.attr(),
    stakes_is_median: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    stakes: _emberData.default.attr(),
    state: _emberData.default.attr(),
    state_name: _emberData.default.attr(),
    state_events: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    steering_committee_description: _emberData.default.attr(),
    project_year: _emberData.default.attr("string"),
    steering_committee_date: _emberData.default.attr("string"),
    strategic_lever: _emberData.default.attr("string"),
    strategy: _emberData.default.attr(),
    strategy_is_median: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    street_number: _emberData.default.attr(),
    submitter_name: _emberData.default.attr("string"),
    synchronized_at: _emberData.default.attr(),
    tags: _emberData.default.hasMany("tag", {
      async: false
    }),
    team_ids: _emberData.default.attr(),
    teammates: _emberData.default.hasMany("user", {
      async: false
    }),
    to_be_evaluated: _emberData.default.attr("boolean"),
    todos: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    total_rating: _emberData.default.attr(),
    turnover: _emberData.default.attr("number"),
    turnover_explanation: _emberData.default.attr("string"),
    typology: _emberData.default.attr(),
    updated_at: _emberData.default.attr(),
    user_id: _emberData.default.attr(),
    validation_date: _emberData.default.attr(),
    ask_level2_validation_date: _emberData.default.attr(),
    visible: _emberData.default.attr("boolean"),
    voteIsDisabled: _emberData.default.attr("boolean"),
    zipcode: _emberData.default.attr(),
    draft: Ember.computed.equal("state", "draft"),
    published: Ember.computed.equal("state", "published"),
    selected: Ember.computed.equal("state", "selected"),
    rejected: Ember.computed.equal("state", "rejected"),
    is_master: Ember.computed.equal("replication_status", 1),
    is_replica: Ember.computed.equal("replication_status", 2),
    markers: Ember.computed("lat", "lng", "completion", function () {
      return [Ember.Object.create({
        latitude: this.lat,
        longitude: this.lng,
        typology: this.completion
      })];
    }),
    allocatedBudget: Ember.computed("total_rating", "likeValue", function () {
      return this.total_rating * this.likeValue;
    }),
    percentBudget: Ember.computed("model.idea.total_rating", "model.idea.budget", "allocatedBudget", function () {
      return this.budget > 0 ? this.allocatedBudget / this.budget : 1;
    }),
    hasScopes: Ember.computed("scopes.[]", function () {
      return this.scopes.length > 0;
    }),
    possible_state_events: Ember.computed("state_events.[]", function () {
      return this.state_events.filterBy("can_fire", true).sortBy("position");
    }),
    replicas_count: Ember.computed.alias("replicas.length"),
    scope_hubs_count: Ember.computed.alias("scope_hubs.length"),
    scope_clusters_count: Ember.computed.alias("scope_clusters.length"),
    scope_countries_count: Ember.computed.alias("scope_countries.length"),
    scope_entities_count: Ember.computed.alias("scope_entities.length"),
    scope_BLs_count: Ember.computed.alias("scope_BLs.length"),
    regularComments: Ember.computed("comments.[]", function () {
      return this.comments.filterBy("admin", false);
    }),
    adminComments: Ember.computed("comments.[]", function () {
      return this.comments.filterBy("admin", true);
    }),
    scope_hubs: Ember.computed("scopes.[]", function () {
      return this.scopes.filterBy("level", "Hub");
    }),
    scope_clusters: Ember.computed("scopes.[]", function () {
      return this.scopes.filterBy("level", "Cluster");
    }),
    scope_countries: Ember.computed("scopes.[]", function () {
      return this.scopes.filterBy("level", "Country");
    }),
    scope_entities: Ember.computed("scopes.[]", function () {
      return this.scopes.filterBy("level", "Entity");
    }),
    scope_BLs: Ember.computed("scopes.[]", function () {
      return this.scopes.filterBy("level", "Bl");
    }),
    followText: Ember.computed("followed", function () {
      if (this.followed) {
        return this.localeService.t("ideas.show.unfollow");
      }

      return this.localeService.t("ideas.show.follow");
    }),
    currentUserHasToEvaluate: Ember.computed("to_be_evaluated", "current_user_evaluation_completed", function () {
      return this.to_be_evaluated && !this.current_user_evaluation_completed;
    }),
    previousState: function previousState() {
      return this._callAdapter("previousState");
    },
    nextState: function nextState() {
      return this._callAdapter("nextState");
    },
    changeState: function changeState(event) {
      return this._callAdapter("changeState", event);
    },
    updateColor: function updateColor(color) {
      return this._callAdapter("updateColor", color);
    },
    toggleFollow: function toggleFollow() {
      return this._callAdapter("toggleFollow");
    },
    toggleContribute: function toggleContribute() {
      return this._callAdapter("toggleContribute");
    },
    toggleReplicate: function toggleReplicate() {
      return this._callAdapter("toggleReplicate");
    },
    addTeam: function addTeam(userId) {
      return this._callAdapter("addTeam", userId);
    },
    requestExpert: function requestExpert(userId) {
      return this._callAdapter("requestExpert", userId);
    },
    multipleEvaluations: function multipleEvaluations() {
      return this._callAdapter("multipleEvaluations");
    },
    updateRating: function updateRating(rating) {
      return this._callAdapter("updateRating", rating);
    },
    convertToConcept: function convertToConcept() {
      return this._callAdapter("convertToConcept");
    },
    createDepartment: function createDepartment() {
      return this._callAdapter("createDepartment");
    },
    updateDepartment: function updateDepartment() {
      return this._callAdapter("updateDepartment");
    },
    changeChallenge: function changeChallenge(target) {
      return this._callAdapter("changeChallenge", target);
    },
    toggleIsMedian: function toggleIsMedian(toolName, isMedian) {
      return this._callAdapter("toggleIsMedian", toolName, isMedian);
    },
    reject: function reject() {
      return this._callAdapter("reject");
    },
    synchronize: function synchronize() {
      return this._callAdapter("synchronize", this.get("mirror_target_domain.id"));
    },
    translate: function translate(language) {
      return this._callAdapter("translate", language);
    },
    updateCurrentUserEvaluation: function updateCurrentUserEvaluation(data) {
      return this._callAdapter("updateCurrentUserEvaluation", data);
    },
    toggleDeployed: function toggleDeployed(scope) {
      return this._callAdapter("toggleDeployed", scope);
    },
    toggleActionCompleted: function toggleActionCompleted(todo) {
      return this._callAdapter("toggleActionCompleted", todo);
    },
    askForValidation: function askForValidation(params) {
      return this._callAdapter("askForValidation", params);
    },
    updateExpertValidations: function updateExpertValidations(validations) {
      return this._callAdapter("updateExpertValidations", validations);
    },
    resetExpertValidations: function resetExpertValidations() {
      return this._callAdapter("resetExpertValidations");
    }
  });

  _exports.default = _default;
});