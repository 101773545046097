define("innowaze-ember2/routes/ideas", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/mixins/routes/setCurrentChallenge", "innowaze-ember2/mixins/routes/resetScroll"], function (_exports, _objectSpread2, _selectAutocomplete, _setCurrentChallenge, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_selectAutocomplete.default, _resetScroll.default, _setCurrentChallenge.default, {
    notifications: Ember.inject.service(),
    locale: Ember.inject.service(),
    queryParams: {
      _states: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        challenge: this.store.findRecord("challenge", params.challenge_id),
        supertags: this.store.query("tag", {
          supertags: true,
          challenge_id: params.challenge_id
        }).then(function (supertag) {
          return supertag.toArray();
        })
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      // Here we can't use setCurrentChallenge Mixin because we need to define afterModel hook for specific task
      this.session.set("currentChallenge", model.challenge);
      this.store.queryRecord("user", {}).then(function (user) {
        _this.session.set("currentUser", user);

        var completed = model.challenge.get("current_profile_completion");

        if (!completed) {
          _this.transitionTo("users.editForChallenge", model.challenge.id, _this.get("session.currentUser.id"), {
            queryParams: {
              from: JSON.stringify([transition.targetName, model.challenge.id])
            }
          });
        }
      });
    },
    resetController: function resetController(controller, isExiting) {
      this.resetCriteria();
      controller.set("availableEmailTemplates", null);

      if (isExiting) {
        var queryParams = controller.queryParams;
        queryParams.forEach(function (param) {
          return controller.set(param, null);
        });
        controller.set("sort", "created_at");
        controller.set("isReadyToLoadMore", false);
        controller.set("setupControllerReady", false);
        controller.set("page", 1);
        controller.selectedIdeas.setEach("isSelected", false);
      }
    },
    setupController: function setupController(controller, model, transition) {
      var _this2 = this;

      this._super.apply(this, arguments);

      var promises = [];
      controller.set("setupControllerReady", false); // reset ideas and count when challenge is changed

      controller.set("ideas", []);
      controller.set("ideaCount", 0);
      controller.set("availableMeteoIcons", null);
      controller.set("onlyToEvaluate", false);
      controller.set("current_user_given_points", model.challenge.get("current_user_given_points"));
      controller.set("displayMode", model.challenge.get("display_default_list") ? "list" : "block-list");
      controller.set("tagsToDisplay", []);
      controller.tagsToDisplay.pushObjects(this.currentModel.supertags.toArray());
      controller.set("tags", []);

      if (controller._tags) {
        controller._tags.split(",").forEach(function (id) {
          promises.push(_this2.store.findRecord("tag", id, {
            backgroundReload: false
          }).then(function (tag) {
            tag.set("selected", true);
            controller.tagsToDisplay.pushObject(tag);
            controller.tags.pushObject(tag);
          }).catch(function () {}));
        });
      } // Initialize sorting criterias


      controller.set("availableSortingCriteria", []);

      if (!model.challenge.hide_votes_ideas) {
        controller.availableSortingCriteria.pushObject({
          id: "computedScore",
          title: this.locale.t("ideas.index.score")
        });
      }

      controller.availableSortingCriteria.pushObjects([{
        id: "",
        title: this.locale.t("ideas.index.relevance")
      }, {
        id: "workflow",
        title: this.locale.t("ideas.index.state")
      }, {
        id: "userScore",
        title: this.locale.t("ideas.index.user_score")
      }, {
        id: "commentsCount",
        title: this.locale.t("ideas.index.comments_count")
      }, {
        id: "replicationsCount",
        title: this.locale.t("challenges.replication.interests")
      }, {
        id: "commentDate",
        title: this.locale.t("ideas.index.comment_date")
      }, {
        id: "color",
        title: this.locale.t("ideas.index.color")
      }, {
        id: "created_at",
        title: this.locale.t("ideas.index.creation_date")
      }, {
        id: "savings",
        title: this.locale.t("ideas.index.savings")
      }]); // Filter by tags

      if (controller.tag) {
        this.store.adapterFor("tag").searchTags(model.challenge.id, {
          query: controller.tag
        }).then(function (tags) {
          controller.tagsToDisplay.pushObjects(tags);
          var tag = tags.findBy("name", controller.tag);

          if (tag) {
            controller.set("tags", [tag]);
            controller.set("isReadyToLoadMore", true);
          }
        });
      } // Filter by states


      var available_states = [];
      available_states.pushObjects(model.challenge.get("state_machine_rules"));
      controller.set("availableStates", available_states);

      if (controller._states) {
        controller._states.split(",").forEach(function (s) {
          var state = controller.availableStates.findBy("to", s);

          if (state) {
            controller.states.pushObject(state);
          }
        });
      }

      if (controller.state) {
        var selectedState = model.challenge.get("state_machine_rules").findBy("to", controller.state);

        if (selectedState) {
          controller.set("states", [selectedState]);
        }
      } // Filter by departments


      controller.set("departmentsToDisplay", []);

      if (controller._departments) {
        controller._departments.split(",").forEach(function (id) {
          promises.push(_this2.store.findRecord("department", id, {
            backgroundReload: false
          }).then(function (dpt) {
            controller.departmentsToDisplay.pushObject(dpt);
            controller.departments.pushObject(dpt);
          }).catch(function () {}));
        });
      } // Filter by creators


      var creatorsPromise = this.store.adapterFor("user").ideaCreatorsSearch(model.challenge.id).then(function (users) {
        return controller.set("availableCreators", users);
      });

      if (controller._creators) {
        promises.push(creatorsPromise);
        creatorsPromise.then(function () {
          controller._creators.split(",").forEach(function (id) {
            var user = controller.availableCreators.findBy("id", parseInt(id));

            if (user) {
              controller.creators.pushObject(user);
            }
          });
        });
      } // Build tagcloud


      controller.set("tagsCloud", model.challenge.get("tags_cloud").map(function (tag) {
        return Ember.Object.create(tag);
      })); // Filter by typology

      var past_future = model.challenge.get("ideas").filterBy("active", true).findBy("name", "past_future");

      if (past_future) {
        controller.set("available_typologies", past_future.get("params").typology);
      } // Filter by meteo


      var meteo = model.challenge.get("ideas").filterBy("active", true).findBy("name", "meteo");

      if (meteo) {
        controller.set("availableMeteoIcons", meteo.get("params").icons);
      } // Filter by product lines


      controller.set("availableProductLines", null);
      var product_line = model.challenge.get("ideas").filterBy("active", true).findBy("name", "product_line");

      if (product_line) {
        controller.set("availableProductLines", []);
        var products = product_line.get("params").product_line;
        products.forEach(function (v, i) {
          controller.availableProductLines.push({
            id: i,
            name: v
          });
        });

        if (controller._product_lines) {
          controller._product_lines.split(",").forEach(function (s) {
            var product_line = controller.availableProductLines.findBy("name", s);

            if (product_line) {
              controller.product_lines.pushObject(product_line);
            }
          });
        }
      } // Filter by customer market


      var customer_market = model.challenge.get("ideas").filterBy("active", true).findBy("name", "customer_market");

      if (customer_market) {
        controller.set("availableCustomerMarkets", []);
        var segments = customer_market.get("params").customer_market;
        segments.forEach(function (v, i) {
          controller.availableCustomerMarkets.push({
            id: i,
            name: v
          });
        });
      } // Filter by purchasing categories


      var purchasing_category = model.challenge.get("ideas").filterBy("active", true).findBy("name", "purchasing_category");

      if (purchasing_category) {
        controller.set("availablePurchasingCategories", []);
        var categories = purchasing_category.get("params").purchasing_category;
        categories.forEach(function (v, i) {
          controller.availablePurchasingCategories.push({
            id: i,
            name: v
          });
        });
      } // Filter by methodologies and  strategic levers


      controller.set("availableMethodologies", null);
      controller.set("availableStrategicLevers", null);
      var purchasing_levers = model.challenge.get("ideas").filterBy("active", true).findBy("name", "purchasing_levers");

      if (purchasing_levers) {
        controller.set("availableMethodologies", []);
        var methodologies = purchasing_levers.get("params").methodologies;
        methodologies.forEach(function (v, i) {
          controller.availableMethodologies.push({
            id: i,
            name: v
          });
        });
        controller.set("availableStrategicLevers", []);
        var strategic_levers = purchasing_levers.get("params").strategic_levers;
        strategic_levers.forEach(function (v, i) {
          controller.availableStrategicLevers.push({
            id: i,
            name: v
          });
        });

        if (controller._methodologies && controller._methodologies.length > 0) {
          controller._methodologies.split(",").forEach(function (s) {
            var methodology = controller.availableMethodologies.findBy("name", s);

            if (methodology) {
              controller.methodologies.pushObject(methodology);
            }
          });
        }

        if (controller._strategic_levers && controller._strategic_levers.length > 0) {
          controller._strategic_levers.split(",").forEach(function (s) {
            var strategic_lever = controller.availableStrategicLevers.findBy("name", s);

            if (strategic_lever) {
              controller.strategic_levers.pushObject(strategic_lever);
            }
          });
        }
      } // Filter by hermes categories


      controller.set("availableHermesCategories", null);
      var purchasing_family = model.challenge.get("ideas").filterBy("active", true).findBy("name", "purchasing_family");

      if (purchasing_family) {
        controller.set("purchasingFamilyTitle", purchasing_family.get("title"));
        var hermesPromise = this.store.findAll("purchasingFamily").then(function (purchasingFamilies) {
          controller.set("availableHermesCategories", purchasingFamilies);
        });

        if (controller._hermes_categories) {
          promises.push(hermesPromise);
          hermesPromise.then(function () {
            controller._hermes_categories.split(",").forEach(function (hc) {
              var hermes_category = controller.availableHermesCategories.findBy("id", hc);

              if (hermes_category) {
                controller.hermes_categories.pushObject(hermes_category);
              }
            });
          });
        }
      } // Filter by scopes


      controller.set("availableScopes", null);
      var scope = model.challenge.get("ideas").filterBy("active", true).findBy("name", "scope");
      var light_scope = model.challenge.get("ideas").filterBy("active", true).findBy("name", "light_scope");

      if (scope) {
        controller.set("ScopeTitle", scope.get("title"));
        var scopesPromise = this.store.query("scope", scope.params).then(function (scopes) {
          controller.set("availableScopes", scopes);
          controller.set("availableOwnerScopes", controller.availableScopes.filterBy("level", "Entity"));
        });

        if (controller._scopes) {
          promises.push(scopesPromise);
          scopesPromise.then(function () {
            controller._scopes.split(",").forEach(function (s) {
              var scope = controller.availableScopes.findBy("id", s);

              if (scope) {
                controller.scopes.pushObject(scope);
              }
            });
          });
        }

        if (controller._owner_scopes) {
          promises.push(scopesPromise);
          scopesPromise.then(function () {
            controller._owner_scopes.split(",").forEach(function (s) {
              var scope = controller.availableScopes.findBy("id", s);

              if (scope) {
                controller.owner_scopes.pushObject(scope);
              }
            });
          });
        }
      }

      if (light_scope) {
        controller.set("ScopeTitle", light_scope.get("title"));

        var _scopesPromise = this.store.query("scope", light_scope.params).then(function (scopes) {
          controller.set("availableScopes", scopes);
        });

        if (controller._scopes) {
          promises.push(_scopesPromise);

          _scopesPromise.then(function () {
            controller._scopes.split(",").forEach(function (s) {
              var scope = controller.availableScopes.findBy("id", s);

              if (scope) {
                controller.scopes.pushObject(scope);
              }
            });
          });
        }
      } // Filter by domains


      var domainPromise = this.store.query("domain", {
        challenge_id: model.challenge.id
      }).then(function (domains) {
        return controller.set("availableDomains", domains);
      });

      if (controller._domains) {
        promises.push(domainPromise);
        domainPromise.then(function () {
          controller._domains.split(",").forEach(function (id) {
            var domain = controller.availableDomains.findBy("id", id);

            if (domain) {
              controller.domains.pushObject(domain);
            }
          });
        });
      } // Filter by sites


      var sitesPromise = this.store.findAll("user-field-list").then(function (data) {
        controller.set("availableSites", []);
        data.forEach(function (d, i) {
          if (!d.get("element") || d.get("type") !== "site") {
            return;
          }

          controller.availableSites.push({
            id: i,
            name: d.get("element")
          });
        });
      });

      if (controller._sites) {
        promises.push(sitesPromise);
        sitesPromise.then(function () {
          controller._sites.split(",").forEach(function (id) {
            var site = controller.availableSites.findBy("id", parseInt(id));

            if (site) {
              controller.sites.pushObject(site);
            }
          });
        });
      } // Filter by features


      if (model.challenge.get("featuresIdeaModule")) {
        var features = Ember.A(Array(model.challenge.featuresIdeaSelectors.length).fill(null));

        if (controller._features) {
          var values = controller._features.split(",");

          if (values.length === features.length) {
            values.forEach(function (value, index) {
              features.set(index, values[index]);
            });
          }
        }

        controller.set("features", features);
      } // Filter by solutions


      var solutionModule = model.challenge.get("ideas").filterBy("active", true).findBy("name", "solution");

      if (solutionModule) {
        var solutionsPromise = this.store.query("solution", {}).then(function (solutions) {
          controller.set("allSolutions", solutions);
        });

        if (controller._solution) {
          promises.push(solutionsPromise);
          solutionsPromise.then(function () {
            var solutionsTree = [];
            var solution = controller.allSolutions.findBy("id", controller._solution);

            if (!solution) {
              return;
            }

            solutionsTree.pushObject(solution.id);

            if (solution.parent) {
              var parent = controller.allSolutions.findBy("id", solution.parent);
              solutionsTree.unshiftObject(solution.parent);

              if (parent && parent.parent) {
                solutionsTree.unshiftObject(parent.parent);
              }
            }

            if (solutionsTree.length > 0) {
              controller.set("organizationFilter", solutionsTree[0] || null);
              controller.set("activityFilter", solutionsTree[1] || null);
              controller.set("solutionFilter", solutionsTree[2] || null);
            }
          });
        }
      }

      controller.set("colorVars", model.challenge.get("tools").filterBy("active", true).concat([{
        title: this.locale.t("ideas.types"),
        name: "typology",
        scale: 1
      }, {
        title: this.locale.t("ideas.state"),
        name: "state_name",
        scale: 2
      }, {
        title: this.locale.t("ideas.domains"),
        name: "first_domain",
        scale: 2
      }]));
      controller.set("sizeVars", model.challenge.get("tools").filterBy("active", true));
      controller.set("selectedColor", controller.colorVars.findBy("name", "first_domain"));
      controller.set("selectedSize", controller.sizeVars.findBy("name", "stakes"));

      if (this.get("session.isModerator")) {
        this.store.adapterFor("email-template").getTemplates().then(function (templates) {
          controller.set("availableEmailTemplates", templates);
        });
      }

      if (controller._jury) {
        controller.set("jury", model.challenge.juryWithIdeas.find(function (j) {
          return j.id === controller._jury;
        }));
      }

      Promise.all(promises).then(function () {
        if (controller.hasFilters) {
          controller.set("filtersHidden", false);
        }

        _this2.searchIdeas().then(function (result) {
          controller.setProperties({
            isReadyToLoadMore: true,
            ideas: result.toArray(),
            ideaCount: result.get("meta.total_results"),
            isLoading: false
          });
          controller.set("setupControllerReady", true);
        });
      });
      var route = this;
      controller.reopen({
        sortingCriteriaChanged: Ember.observer("selectedSortingCriteria", function () {
          this.set("sort", this.selectedSortingCriteria);
          Ember.run.next(route, function () {
            this.updateIdeaList();
          });
        })
      });
    },
    resetIdeaList: function resetIdeaList() {
      this.controller.setProperties({
        users: [],
        ideas: [],
        page: 1
      });
    },
    getOptions: function getOptions() {
      var query = this.get("controller.query");
      var order = this.get("controller.sort");
      var tags = this.get("controller.tags");
      var departments = this.get("controller.departments");
      var challenges = this.get("controller.challenges");
      var page = this.get("controller.page");
      var color = this.get("controller.color");
      var typology = this.get("controller.typology");
      var users = this.get("controller.creators");
      var sites = this.get("controller.sites");
      var methodologies = this.get("controller.methodologies");
      var strategic_levers = this.get("controller.strategic_levers");
      var hermes_categories = this.get("controller.hermes_categories");
      var scopes = this.get("controller.scopes");
      var owner_scopes = this.get("controller.owner_scopes");
      var completion = this.get("controller.completion");
      var reported_in_smart = this.get("controller.reported_in_smart");
      var generalization = this.get("controller.generalization");
      var product_lines = this.get("controller.product_lines");
      var customer_markets = this.get("controller.customer_markets");
      var purchasing_categories = this.get("controller.purchasing_categories");
      var displayMode = this.get("controller.displayMode");
      var map_mode = this.get("controller.mapDisplay");
      var states = this.get("controller.states");
      var domains = this.get("controller.domains");
      var meteo = this.get("controller.meteo");
      var replication_status = this.get("controller.replication_status");
      var solution = this.get("controller.solutionFilter") || this.get("controller.activityFilter") || this.get("controller.organizationFilter") || null;
      var published_at_start = this.get("controller._publication_at_start");
      var published_at_end = this.get("controller._publication_at_end");
      var features = this.get("controller.features");
      return (0, _objectSpread2.default)({}, {
        challenge_id: this.currentModel.challenge.id,
        extended: true
      }, {}, states && states.length && {
        states: states.mapBy("to")
      }, {}, domains && domains.length > 0 && {
        domains: domains.mapBy("id")
      }, {}, features && features.without(null).length > 0 && {
        features: features
      }, {}, query && {
        query: query
      }, {}, order && {
        order: order
      }, {}, color && {
        color: color
      }, {}, meteo && {
        meteo: meteo.id
      }, {}, typology && {
        typology: typology
      }, {}, replication_status && {
        replication_status: replication_status
      }, {}, completion && {
        completion: completion
      }, {}, reported_in_smart && {
        reported_in_smart: reported_in_smart
      }, {}, generalization && {
        generalization: generalization
      }, {}, tags && tags.length > 0 && {
        tags: tags.mapBy("name")
      }, {}, sites && sites.length > 0 && {
        sites: sites.mapBy("name")
      }, {}, methodologies && methodologies.length > 0 && {
        methodologies: methodologies.mapBy("name")
      }, {}, strategic_levers && strategic_levers.length > 0 && {
        strategic_levers: strategic_levers.mapBy("name")
      }, {}, hermes_categories && hermes_categories.length > 0 && {
        hermes_categories: hermes_categories.mapBy("id")
      }, {}, scopes && scopes.length > 0 && {
        scopes: scopes.mapBy("id")
      }, {}, owner_scopes && owner_scopes.length > 0 && {
        owner_scopes: owner_scopes.mapBy("id")
      }, {}, product_lines && product_lines.length > 0 && {
        product_lines: product_lines.mapBy("name")
      }, {}, customer_markets && customer_markets.length > 0 && {
        customer_markets: customer_markets.mapBy("name")
      }, {}, purchasing_categories && purchasing_categories.length > 0 && {
        purchasing_categories: purchasing_categories.mapBy("name")
      }, {}, departments && departments.length > 0 && {
        departments: departments.mapBy("id")
      }, {}, users && users.length > 0 && {
        users: users.mapBy("id")
      }, {}, challenges && challenges.length > 0 && {
        challenges: challenges.mapBy("id")
      }, {}, page && {
        page: page
      }, {}, map_mode && {
        map_mode: map_mode
      }, {}, {
        list: displayMode === "list" || displayMode === "block-list"
      }, {}, {
        paginate: displayMode === "list" || displayMode === "block-list"
      }, {}, this.get("controller.shortcut") || {}, {}, solution && {
        solution: solution
      }, {}, published_at_start && {
        published_at_start: published_at_start
      }, {}, published_at_end && {
        published_at_end: published_at_end
      });
    },
    searchIdeas: function searchIdeas() {
      var options = this.getOptions();
      return this.store.query("idea", options);
    },
    updateIdeaList: function updateIdeaList() {
      var _this3 = this;

      this.controller.set("isLoading", true);

      this._setQueryParams();

      this.resetIdeaList();
      this.searchIdeas().then(function (result) {
        var ideaToEvaluateCount;

        if (_this3.controller.onlyToEvaluate) {
          ideaToEvaluateCount = _this3.controller.jury ? _this3.controller.jury.ideas_not_evaluated_count : _this3.currentModel.challenge.current_user_evaluation_states.find(function (s) {
            return s.to === _this3.controller._states;
          }).ideas_not_evaluated_count;
        }

        _this3.controller.setProperties({
          ideas: _this3.controller.onlyToEvaluate ? result.toArray().filterBy("currentUserHasToEvaluate", true) : result.toArray(),
          ideaCount: _this3.controller.onlyToEvaluate ? ideaToEvaluateCount : result.get("meta.total_results"),
          isLoading: false
        });
      });
    },
    resetCriteria: function resetCriteria() {
      this.controller.setProperties({
        query: "",
        shortcut: {},
        states: [],
        domains: [],
        selectedIdea: undefined,
        rejectedIdea: undefined,
        typology: undefined,
        followedIdeas: undefined,
        myIdeas: undefined,
        teammateIdeas: undefined,
        completion: undefined,
        generalization: undefined,
        color: undefined,
        meteo: undefined,
        unclassed_ideas: undefined,
        replication_status: undefined,
        creators: [],
        tags: [],
        departments: [],
        product_lines: [],
        customer_markets: [],
        purchasing_categories: [],
        sites: [],
        methodologies: [],
        strategic_levers: [],
        hermes_categories: [],
        scopes: [],
        owner_scopes: [],
        solutionFilter: null,
        activityFilter: null,
        organizationFilter: null,
        _publication_at_start: null,
        _publication_at_end: null,
        features: this.currentModel.challenge.get("featuresIdeaModule") ? Ember.A(Array(this.currentModel.challenge.featuresIdeaSelectors.length).fill(null)) : Ember.A()
      });
      var labels = $("label");
      var results = [];

      for (var i = 0, len = labels.length; i < len; i++) {
        var label = labels[i];
        var toggable = $(label).next(".toggable");
        $(label).addClass("closed").removeClass("opened");
        results.push(toggable.addClass("hide"));
      }

      return results;
    },
    _success: function _success() {
      this.notifications.success("Modifications effectués");
    },
    _error: function _error(_ref) {
      var errors = _ref.errors;
      this.notifications.errorWithList("Une erreur s'est produite", errors);
    },
    _setQueryParams: function _setQueryParams() {
      this.controller.setProperties({
        _jury: this.get("controller.jury.id") || null,
        _tags: this.get("controller.tags").mapBy("id").join(",") || null,
        _departments: this.get("controller.departments").mapBy("id").join(",") || null,
        _sites: this.get("controller.sites").mapBy("name").join(",") || null,
        _states: this.get("controller.states").mapBy("to").join(",") || null,
        _domains: this.get("controller.domains").mapBy("id").join(",") || null,
        _creators: this.get("controller.creators").mapBy("id").join(",") || null,
        _hermes_categories: this.get("controller.hermes_categories").mapBy("id").join(",") || null,
        _scopes: this.get("controller.scopes").mapBy("id").join(",") || null,
        _owner_scopes: this.get("controller.owner_scopes").mapBy("id").join(",") || null,
        _product_lines: this.get("controller.product_lines").mapBy("name").join(",") || null,
        _methodologies: this.get("controller.methodologies").mapBy("name").join(",") || null,
        _strategic_levers: this.get("controller.strategic_levers").mapBy("name").join(",") || null,
        _solution: this.get("controller.solutionFilter") || this.get("controller.activityFilter") || this.get("controller.organizationFilter") || null,
        _features: this.get("controller.features").without(null).length > 0 ? this.get("controller.features").join(",") : null
      });
    },
    actions: {
      selectFeature: function selectFeature() {
        this.updateIdeaList();
      },
      setSolution: function setSolution() {
        this.updateIdeaList();
      },
      selectAll: function selectAll() {
        this.get("controller.ideas").setEach("isSelected", true);
        this.set("controller.allSelected", true);
      },
      unselectAll: function unselectAll() {
        this.get("controller.ideas").setEach("isSelected", false);
        this.set("controller.allSelected", false);
      },
      resetFilters: function resetFilters() {
        this.resetCriteria();
        this.updateIdeaList();
      },
      toggleTypology: function toggleTypology(typology) {
        if (this.get("controller.typology") === typology) {
          this.set("controller.typology", undefined);
        } else {
          this.set("controller.typology", typology);
        }

        this.updateIdeaList();
      },
      selectTag: function selectTag(tag) {
        this.set("controller.tags", [tag]);
        this.updateIdeaList();
      },
      selectCompletion: function selectCompletion(completion) {
        this.set("controller.completion", this.get("controller.completion") === completion ? undefined : completion);
        this.updateIdeaList();
      },
      selectPurchasingSmart: function selectPurchasingSmart(reported_in_smart) {
        this.set("controller.reported_in_smart", this.get("controller.reported_in_smart") === reported_in_smart ? undefined : reported_in_smart);
        this.updateIdeaList();
      },
      removePurchasingSmartFilter: function removePurchasingSmartFilter() {
        this.set("controller.reported_in_smart", undefined);
        this.updateIdeaList();
      },
      selectGeneralization: function selectGeneralization(generalization) {
        this.set("controller.generalization", this.get("controller.generalization") === generalization ? undefined : generalization);
        this.updateIdeaList();
      },
      setPublicationAtStart: function setPublicationAtStart(date) {
        this.set("controller._publication_at_start", date ? moment(date).format("YYYY-MM-DD") : null);
        this.updateIdeaList();
      },
      setPublicationAtEnd: function setPublicationAtEnd(date) {
        this.set("controller._publication_at_end", date ? moment(date).format("YYYY-MM-DD") : null);
        this.updateIdeaList();
      },
      toggleState: function toggleState(state) {
        if (this.get("controller.states").findBy("to", state.to)) {
          this.get("controller.states").removeObject(state);
        } else {
          this.get("controller.states").pushObject(state);
        }

        this.updateIdeaList();
      },
      switchState: function switchState(state) {
        if (this.get("controller.states").findBy("to", state.to)) {
          this.set("controller.states", []);
        } else {
          this.set("controller.states", [state]);
        }

        this.updateIdeaList();
      },
      selectColor: function selectColor(color) {
        if (this.get("controller.color") === color) {
          this.set("controller.color", undefined);
        } else {
          this.set("controller.color", color);
        }

        this.updateIdeaList();
      },
      selectReplicationStatus: function selectReplicationStatus(status) {
        if (this.get("controller.replication_status") === status) {
          this.set("controller.replication_status", undefined);
        } else {
          this.set("controller.replication_status", status);
        }

        this.updateIdeaList();
      },
      toggleMeteo: function toggleMeteo(meteo) {
        if (this.get("controller.meteo.id") === meteo.id) {
          this.set("controller.meteo", undefined);
        } else {
          this.set("controller.meteo", meteo);
        }

        this.updateIdeaList();
      },
      selectDomain: function selectDomain(domain) {
        if (this.get("controller.domains").findBy("id", domain.id)) {
          this.get("controller.domains").removeObject(domain);
        } else {
          this.get("controller.domains").pushObject(domain);
        }

        this.updateIdeaList();
      },
      toggleJury: function toggleJury(jury) {
        if (this.controller.jury === jury) {
          this.resetCriteria();
        } else {
          this.resetCriteria();
          this.controller.setProperties({
            domains: jury.domains,
            tags: jury.tags,
            scopes: jury.scopes,
            states: this.controller.availableStates.filterBy("to", jury.state)
          });
          this.set("controller.jury", jury);
        }

        this.updateIdeaList();
      },
      search: function search() {
        this.updateIdeaList();
      },
      exportCSV: function exportCSV() {
        var _this4 = this;

        var options = this.getOptions();
        this.currentModel.challenge.exportIdeasCSV(options).then(function (json) {
          _this4.notifications.success(_this4.locale.t("ideas.index.csv_file_sent"));
        });
      },
      // Bulk actions
      sendByEmail: function sendByEmail() {
        var _this5 = this;

        this.store.adapterFor("idea").sendNotification(this.get("controller.emailRecipients").split(","), this.get("controller.selectedIdeas")).then(function () {
          // success
          _this5.notifications.success(_this5.locale.t("notifications_sent"));
        }, function () {
          // error
          _this5.notifications.error(_this5.locale.t("notifications_not_sent"));
        });
      },
      nextStage: function nextStage() {
        var _this6 = this;

        Promise.all(this.get("controller.selectedIdeas").map(function (idea) {
          return idea.nextState();
        })).then(function () {
          return _this6._success();
        }, function (errors) {
          return _this6._error(errors);
        });
      },
      previousStage: function previousStage() {
        var _this7 = this;

        Promise.all(this.get("controller.selectedIdeas").map(function (idea) {
          return idea.previousState();
        })).then(function () {
          return _this7._success();
        }, function (errors) {
          return _this7._error(errors);
        });
      },
      toggleFollow: function toggleFollow() {
        var _this8 = this;

        Promise.all(this.get("controller.selectedIdeas").map(function (idea) {
          idea.toggleProperty("followed");
          return idea.toggleFollow();
        })).then(function () {
          return _this8._success();
        }, function (errors) {
          return _this8._error(errors);
        });
      },
      changeColor: function changeColor(color) {
        var _this9 = this;

        Promise.all(this.get("controller.selectedIdeas").map(function (idea) {
          idea.set("color", color);
          return idea.updateColor(color);
        })).then(function () {
          return _this9._success();
        }, function (errors) {
          return _this9._error(errors);
        });
      },
      setColor: function setColor(idea) {
        if (this.get("session.isModerator")) {
          var colors = ["red", "orange", "yellow", "green", "blue", "darkblue", "purple", "grey"];
          var i = colors.indexOf(idea.get("color"));

          if (i >= 0 && i <= colors.length) {
            i += 1;
          } else {
            i = 0;
          }

          idea.updateColor(colors[i]);
          idea.set("color", colors[i]);
        }
      },
      newTag: function newTag(name) {
        var newTag = this.store.createRecord("tag", {
          id: name,
          name: name
        });
        this.get("controller.tagsToDisplay").pushObject(newTag);
        this.get("controller.tagsToAdd").pushObject(newTag);
      },
      addTags: function addTags() {
        var _this10 = this;

        this.store.adapterFor("idea").updateTags(this.currentModel.challenge.id, this.get("controller.selectedIdeas"), this.get("controller.tagsToAdd")).then(function () {
          return _this10._success();
        }, function (errors) {
          return _this10._error(errors);
        });
      },
      loadMore: function loadMore() {
        var _this11 = this;

        this.controller.incrementProperty("page");
        this.controller.set("isLoadingMore", true);
        this.searchIdeas().then(function (r) {
          _this11.get("controller.ideas").pushObjects(_this11.controller.onlyToEvaluate && _this11.controller.jury ? r.toArray().filterBy("currentUserHasToEvaluate", true) : r.toArray());

          _this11.controller.setProperties({
            isLoadingMore: false
          });
        });
      },
      showFilters: function showFilters() {
        this.controller.toggleProperty("filtersHidden");
      },
      filterBySupertag: function filterBySupertag(tag) {
        this.set("controller.tags", [tag]);
        this.updateIdeaList();
      },
      updateList: function updateList() {
        this.updateIdeaList();
      },
      removeTagFilter: function removeTagFilter(tag) {
        this.get("controller.tags").removeObject(tag);
        this.updateIdeaList();
      },
      removeDepartementFilter: function removeDepartementFilter(department) {
        this.get("controller.departments").removeObject(department);
        this.updateIdeaList();
      },
      removeSitesFilter: function removeSitesFilter(site) {
        this.get("controller.sites").removeObject(site);
        this.updateIdeaList();
      },
      removeProductFilter: function removeProductFilter(p) {
        this.get("controller.product_lines").removeObject(p);
        this.updateIdeaList();
      },
      removeMarketFilter: function removeMarketFilter(m) {
        this.get("controller.customer_markets").removeObject(m);
        this.updateIdeaList();
      },
      removeMethodologyFilter: function removeMethodologyFilter(m) {
        this.get("controller.methodologies").removeObject(m);
        this.updateIdeaList();
      },
      removeHermesCategoriesFilter: function removeHermesCategoriesFilter(m) {
        this.get("controller.hermes_categories").removeObject(m);
        this.updateIdeaList();
      },
      removeScopeFilter: function removeScopeFilter(m) {
        this.get("controller.scopes").removeObject(m);
        this.updateIdeaList();
      },
      removeOwnerScopeFilter: function removeOwnerScopeFilter(m) {
        this.get("controller.owner_scopes").removeObject(m);
        this.updateIdeaList();
      },
      removeStrategicLeverFilter: function removeStrategicLeverFilter(m) {
        this.get("controller.strategic_levers").removeObject(m);
        this.updateIdeaList();
      },
      removePurchasingFilter: function removePurchasingFilter(p) {
        this.get("controller.purchasing_categories").removeObject(p);
        this.updateIdeaList();
      },
      removeCreatorFilter: function removeCreatorFilter(user) {
        this.get("controller.creators").removeObject(user);
        this.updateIdeaList();
      },
      removeReplicationStatusFilter: function removeReplicationStatusFilter() {
        this.set("controller.replication_status", undefined);
        this.controller.set("replicationStatusName", undefined);
        this.updateIdeaList();
      },
      setDisplayMode: function setDisplayMode(mode) {
        this.controller.set("displayMode", mode);

        if (mode !== "list" && mode !== "block-list") {
          this.updateIdeaList();
        }
      },
      myIdeas: function myIdeas() {
        this.resetCriteria();
        this.set("controller.myIdeas", this.locale.t("ideas.index.my_ideas", {
          ideas: this.currentModel.challenge.nameFor("ideas", "plural_idea")
        }));
        this.controller.set("shortcut", {
          users: [this.get("session.currentUser.id")]
        });
        this.resetIdeaList();
        this.updateIdeaList();
      },
      myFollowedIdeas: function myFollowedIdeas() {
        this.resetCriteria();
        this.set("controller.followedIdeas", this.locale.t("ideas.index.followed_ideas", {
          ideas: this.currentModel.challenge.nameFor("ideas", "plural_idea")
        }));
        this.controller.set("shortcut", {
          followers: [this.get("session.currentUser.id")]
        });
        this.resetIdeaList();
        this.updateIdeaList();
      },
      myTeammateIdeas: function myTeammateIdeas() {
        this.resetCriteria();
        this.set("controller.teammateIdeas", this.locale.t("ideas.index.teammate_ideas", {
          ideas: this.currentModel.challenge.nameFor("ideas", "plural_idea")
        }));
        this.controller.set("shortcut", {
          teammates: [this.get("session.currentUser.id")]
        });
        this.resetIdeaList();
        this.updateIdeaList();
      },
      unclassed: function unclassed() {
        this.set("controller.followedIdeas", undefined);
        this.set("controller.myIdeas", undefined);
        this.set("controller.teammateIdeas", undefined);
        this.set("controller.unclassed_ideas", this.locale.t("ideas.index.unclassed", {
          ideas: this.currentModel.challenge.nameFor("ideas", "plural_idea")
        }));
        this.controller.set("shortcut", {
          must: false,
          paginate: false,
          tags: this.currentModel.supertags.mapBy("name")
        });
        this.resetIdeaList();
        this.updateIdeaList();
      },
      toggleOnlyToEvaluate: function toggleOnlyToEvaluate() {
        this.controller.toggleProperty("onlyToEvaluate");
        this.updateIdeaList();
      }
    }
  });

  _exports.default = _default;
});