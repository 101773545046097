define("innowaze-ember2/controllers/ideas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locale: Ember.inject.service(),
    queryParams: ["_domains", "_tags", "_states", "_creators", "_owner_scopes", "_departments", "query", "sort", "_sites", "color", "typology", "replication_status", "completion", "reported_in_smart", "_methodologies", "_strategic_levers", "_hermes_categories", "_scopes", "generalization", "_product_lines", "_jury", "_solution", "_publication_at_start", "_publication_at_end", "_features"],
    _features: null,
    features: [],
    _tags: null,
    _domains: null,
    _states: null,
    _creators: null,
    _owner_scopes: null,
    _departments: null,
    _product_lines: null,
    _hermes_categories: null,
    _methodologies: [],
    _strategic_levers: [],
    _sites: null,
    _scopes: null,
    generalization: null,
    color: null,
    _jury: null,
    jury: null,
    _publication_at_start: null,
    _publication_at_end: null,
    sort: "created_at",
    displayColor: Ember.computed.or("session.isModerator", "model.challenge.display_color"),
    listDisplay: Ember.computed.equal("displayMode", "list"),
    blockDisplay: Ember.computed.equal("displayMode", "block-list"),
    mapDisplay: Ember.computed.equal("displayMode", "map"),
    graphDisplay: Ember.computed.equal("displayMode", "graph"),
    graphFullscreenDisplay: Ember.computed.equal("displayMode", "graph-fullscreen"),
    chartVisible: Ember.computed.or("graphDisplay", "graphFullscreenDisplay"),
    displayFilters: Ember.computed.not("filtersHidden"),
    displayCheckbox: Ember.computed.and("session.isModerator", "displayFilters"),
    displayBubble: Ember.computed.or("session.isModerator", "model.challenge.display_bubble"),
    displayClustering: Ember.computed.or("session.isModerator", "model.challenge.display_clustering"),
    needsListing: Ember.computed.or("listDisplay", "blockDisplay"),
    typologyOneSelected: Ember.computed.equal("typology", "1"),
    typologyTwoSelected: Ember.computed.equal("typology", "2"),
    typologyThreeSelected: Ember.computed.equal("typology", "3"),
    typologyFourSelected: Ember.computed.equal("typology", "4"),
    colorRedSelected: Ember.computed.equal("color", "red"),
    colorOrangeSelected: Ember.computed.equal("color", "orange"),
    colorYellowSelected: Ember.computed.equal("color", "yellow"),
    colorGreenSelected: Ember.computed.equal("color", "green"),
    colorBlueSelected: Ember.computed.equal("color", "blue"),
    colorDarkBlueSelected: Ember.computed.equal("color", "darkblue"),
    colorPurpleSelected: Ember.computed.equal("color", "purple"),
    colorGreySelected: Ember.computed.equal("color", "grey"),
    masterSelected: Ember.computed.equal("replication_status", "1"),
    replicaSelected: Ember.computed.equal("replication_status", "2"),
    ideas: [],
    // do we show the domain filter in the search
    // show it if the domains idea module is selected and if at least 1 domain is defined
    domainsEnabled: Ember.computed("model.challenge", function () {
      var modules = this.get("model.challenge.ideas").filterBy("active", true);
      var domains = this.get("model.challenge.domains");

      if (modules) {
        var module = modules.find(function (m) {
          return m.name === "domains";
        });
        return module !== undefined && module.active && domains.length > 0;
      }
    }),
    hasSavings: Ember.computed("model.challenge", function () {
      var modules = this.get("model.challenge.ideas");

      if (modules) {
        var savingsModule = modules.find(function (m) {
          return m.name === "savings" && m.active;
        });
        var benefitsModule = modules.find(function (m) {
          return m.name === "benefits" && m.active;
        });
        return benefitsModule || savingsModule;
      }
    }),
    showMapIcon: Ember.computed("matchingAddresses", function () {
      return this.matchingAddresses.length > 0;
    }),
    matchingAddresses: Ember.computed("ideas.[]", function () {
      return this.ideas.filter(function (i) {
        return i.get("hasAddress");
      });
    }),
    markers: Ember.computed("matchingAddresses", function () {
      return this.matchingAddresses.map(function (idea) {
        return Ember.Object.create({
          latitude: idea.get("lat"),
          longitude: idea.get("lng"),
          idea: true,
          name: idea.get("name"),
          id: idea.id,
          challenge_id: idea.get("challenge_id"),
          typology: idea.get("completion")
        });
      });
    }),
    // do we show the typology filter in the search
    // show it if the typology idea module is selected
    typologyEnabled: Ember.computed("model.challenge", function () {
      var modules = this.get("model.challenge.ideas").filterBy("active", true);

      if (modules) {
        var module = modules.find(function (m) {
          return m.name === "typology";
        });
        return module !== undefined && module.active;
      }
    }),
    typologyName: Ember.computed("typology", function () {
      if (this.typology) {
        return [this.locale.t("ideas.type1"), this.locale.t("ideas.type2"), this.locale.t("ideas.type3"), this.locale.t("ideas.type4")][this.typology - 1];
      }
    }),
    replicationStatusName: Ember.computed("replication_status", function () {
      if (this.replication_status) {
        return [this.locale.t("ideas.modules.replica.masters"), this.locale.t("ideas.modules.replica.replicas")][this.replication_status - 1];
      }
    }),
    // do we show the replication_status filter in the search
    // show it if the replication_status idea module is selected
    replicationStatusEnabled: Ember.computed("model.challenge", function () {
      var modules = this.get("model.challenge.ideas").filterBy("active", true);

      if (modules) {
        var module = modules.find(function (m) {
          return m.name === "replica";
        });
        return module !== undefined && module.active;
      }
    }),
    // do we show the solution filter in the search
    // show it if the solution idea module is selected
    solutionEnabled: Ember.computed("model.challenge", function () {
      var modules = this.get("model.challenge.ideas").filterBy("active", true);

      if (modules) {
        var module = modules.find(function (m) {
          return m.name === "solution";
        });
        return module !== undefined && module.active;
      }
    }),
    // do we show the generalization filter in the search
    // show it if the generalization idea module is selected
    generalizationEnabled: Ember.computed("model.challenge.ideas.[]", function () {
      var modules = this.get("model.challenge.ideas").filterBy("active", true);

      if (modules) {
        var module = modules.find(function (m) {
          return m.name === "generalization";
        });
        return module !== undefined && module.active;
      }
    }),
    filtersHidden: true,
    tagList: Ember.computed("model.supertags", "tags.[]", function () {
      var tagIds = this.tags.map(function (tag) {
        return tag.id;
      });
      return this.get("model.supertags").filter(function (tag) {
        return !tagIds.includes(tag.id);
      });
    }),
    query: "",
    tagsToAdd: [],
    creators: [],
    states: [],
    domains: [],
    departments: [],
    sites: [],
    product_lines: [],
    customer_markets: [],
    purchasing_categories: [],
    hermes_categories: [],
    methodologies: [],
    strategic_levers: [],
    scopes: [],
    owner_scopes: [],
    completion: undefined,
    reported_in_smart: undefined,
    isLoading: false,
    isLoadingMore: false,
    page: 1,
    selectedIdeas: Ember.computed.filterBy("ideas", "isSelected", true),
    selectedCount: Ember.computed("selectedIdeas.[]", function () {
      return this.selectedIdeas.length;
    }),
    mapZoom: Ember.computed("model.challenge.mapSettings.mapZoom", function () {
      return this.get("model.challenge.mapSettings.mapZoom") + 1;
    }),
    noSelection: Ember.computed.empty("selectedIdeas.[]"),
    chartWidth: Ember.computed("filtersHidden", "display_mode", "selectedIdeas", function () {
      if (this.filtersHidden || this.displayMode === "graph-fullscreen") {
        return parseInt($("#no-filter-ideas-container").css("width"));
      }

      return parseInt($("#ideas-container").css("width"));
    }),
    chartHeight: Ember.computed("filtersHidden", "display_mode", "selectedIdeas", function () {
      if (this.displayMode === "graph-fullscreen" || this.filtersHidden) {
        return window.innerHeight - 40;
      }

      return 600;
    }),
    matchingIdeasCount: Ember.computed("ideas.[]", function () {
      return this.ideas.filter(function (elt) {
        return elt.state !== "draft";
      }).length;
    }),
    hasFeaturesFilter: Ember.computed("_features", function () {
      return this._features && this._features.split(",").without("").length > 0;
    }),
    hasPublicationFilter: Ember.computed.or("_publication_at_end", "_publication_at_start"),
    hasFilters: Ember.computed("_publication_at_end", "_publication_at_start", "solutionFilter", "meteo", "color", "typologyName", "replicationStatusName", "myIdeas", "teammateIdeas", "followedIdeas", "unclassed_ideas", "generalization", "tags.[]", "creators.[]", "states.[]", "domains.[]", "departments.[]", "sites.[]", "product_lines.[]", "customer_markets.[]", "purchasing_categories.[]", "methodologies.[]", "strategic_levers.[]", "hermes_categories.[]", "scopes.[]", "owner_scopes.[]", "hasFeaturesFilter", "organizationFilter", function () {
      var _this = this;

      return this.solutionFilter || this.meteo || this.color || this.typologyName || this.myIdeas || this.teammateIdeas || this.replicationStatusName || this.followedIdeas || this.unclassed_ideas || this.generalization || this._publication_at_end || this._publication_at_start || this.hasFeaturesFilter || this.organizationFilter || ["tags", "creators", "states", "domains", "departments", "sites", "product_lines", "customer_markets", "purchasing_categories", "methodologies", "strategic_levers", "hermes_categories", "scopes", "owner_scopes"].reduce(function (acc, filterName) {
        return acc + _this.get(filterName).length;
      }, 0) > 0;
    }),
    revelancyFilterActive: Ember.computed.equal("sort", ""),
    workflowFilterActive: Ember.computed.equal("sort", "workflow"),
    scoreFilterActive: Ember.computed.equal("sort", "computedScore"),
    userScoreFilterActive: Ember.computed.equal("sort", "userScore"),
    commentsCountFilterActive: Ember.computed.equal("sort", "commentsCount"),
    replicationsCountFilterActive: Ember.computed.equal("sort", "replicationsCount"),
    commentDateFilterActive: Ember.computed.equal("sort", "commentDate"),
    colorFilterActive: Ember.computed.equal("sort", "color"),
    createdAtFilterActive: Ember.computed.equal("sort", "created_at"),
    savingsFilterActive: Ember.computed.equal("order", "savings"),
    isReadyToLoadMore: false,
    selectedUserIds: Ember.computed.mapBy("selectedIdeas", "author_id"),
    domainsIds: Ember.computed.mapBy("domains", "id"),
    statesIds: Ember.computed.mapBy("states", "to"),
    criteriaChange: Ember.observer("typology", "domains.[]", "departments.[]", "states.[]", "scopes.[]", "scopes.[]", "tags.[]", "completion", "reported_in_smart", "generalization", "color", "meteo", "sites.[]", "creators.[]", "product_lines.[]", "customer_markets.[]", "purchasing_categories.[]", "methodologies.[]", "strategic_levers.[]", "hermes_categories.[]", "methodologies.[]", "shortcut", "query", function () {
      if (!this.setupControllerReady) {
        return;
      }

      this.setProperties({
        jury: null,
        onlyToEvaluate: false
      });
    }),
    displayReviewerBox: Ember.computed("session.isReviewer", "model.challenge.juryWithIdeas.[]", "model.challenge.current_user_evaluation_states.[]", function () {
      return this.session.isReviewer && (this.model.challenge.juryWithIdeas.length > 0 && this.model.challenge.juryWithIdeas.some(function (j) {
        return j.ideas_to_be_evaluated_count > 0;
      }) || this.model.challenge.current_user_evaluation_states && this.model.challenge.current_user_evaluation_states.length > 0 && this.model.challenge.current_user_evaluation_states.some(function (state) {
        return state.ideas_not_evaluated_count > 0;
      }));
    }),
    hasActiveEvaluationByState: Ember.computed("states", "model.challenge.current_user_evaluation_states.[]", function () {
      var _this2 = this;

      return this.states.length === 1 && this.model.challenge.current_user_evaluation_states.some(function (state) {
        return state.ideas_not_evaluated_count > 0 && state.to === _this2._states;
      });
    }),
    // solution filter
    _solution: null,
    allSolutions: [],
    availableOrganizations: Ember.computed.filterBy("allSolutions", "level", "organization"),
    availableActivities: [],
    availableSolutions: [],
    organizationFilter: null,
    activityFilter: null,
    solutionFilter: null,
    populateActivities: Ember.observer("organizationFilter", function () {
      var organization = this.allSolutions.findBy("id", this.organizationFilter);

      if (organization) {
        this.set("availableActivities", this.allSolutions.filter(function (activity) {
          return organization.children.includes(parseInt(activity.id));
        }));
      } else {
        this.set("availableActivities", []);
      }

      this.set("activityFilter", null);
    }),
    populateSolutions: Ember.observer("activityFilter", function () {
      var activity = this.allSolutions.findBy("id", this.activityFilter);

      if (activity) {
        var solutions = this.allSolutions.filter(function (solution) {
          return activity.children.includes(parseInt(solution.id));
        });
        this.set("availableSolutions", solutions);
      } else {
        this.set("availableSolutions", []);
      }

      this.set("solutionFilter", null);
    })
  });

  _exports.default = _default;
});