define("innowaze-ember2/models/challenge", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "ember-data", "innowaze-ember2/configuration", "innowaze-ember2/models/application"], function (_exports, _objectSpread2, _toConsumableArray2, _emberData, _configuration, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    localeService: Ember.inject.service("locale"),
    // attributes about current_user rights only
    current_user_rights: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    current_user_accessibility: _emberData.default.attr("boolean", {
      readOnly: true
    }),
    // attributes present in both versions
    awaiting: _emberData.default.attr("boolean", {
      readOnly: true
    }),
    banner_description: _emberData.default.attr("string"),
    block_votes_concepts: _emberData.default.attr("boolean"),
    block_votes_ideas: _emberData.default.attr("boolean"),
    hide_votes_ideas: _emberData.default.attr("boolean"),
    deepl_translation: _emberData.default.attr("boolean"),
    deepl_languages: _emberData.default.attr(),
    concept_expert_validation: _emberData.default.attr("boolean"),
    concept_management_committee_validation: _emberData.default.attr("boolean"),
    concepts_count: _emberData.default.attr("number"),
    created_at: _emberData.default.attr(),
    current_profile_completion: _emberData.default.attr("boolean"),
    current_user_admin: _emberData.default.attr("boolean", {
      readOnly: true
    }),
    current_user_id: _emberData.default.attr("number", {
      readOnly: true
    }),
    department: _emberData.default.attr(),
    description: _emberData.default.attr("string"),
    dict: _emberData.default.attr(),
    display_clustering: _emberData.default.attr("boolean"),
    display_domain_filter: _emberData.default.attr("boolean"),
    display_state_filter: _emberData.default.attr("boolean"),
    display_published_at_filter: _emberData.default.attr("boolean"),
    display_user_filters: _emberData.default.attr("boolean"),
    display_tags_cloud: _emberData.default.attr(),
    ends_on: _emberData.default.attr("date"),
    expert_validation: _emberData.default.attr("boolean"),
    fullbath_challenge_id: _emberData.default.attr("string"),
    granted: _emberData.default.attr("boolean"),
    group: _emberData.default.attr(),
    has_showcase: _emberData.default.attr(),
    ideas_count: _emberData.default.attr("number"),
    likers_site_percentage: _emberData.default.attr("boolean"),
    logo_url: _emberData.default.attr("string"),
    management_committee_validation: _emberData.default.attr("boolean"),
    milestones: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    name: _emberData.default.attr("string"),
    no_countdown: _emberData.default.attr("boolean"),
    no_homepage: _emberData.default.attr("boolean"),
    no_timeline: _emberData.default.attr("boolean"),
    position: _emberData.default.attr("number"),
    private: _emberData.default.attr("boolean"),
    requires_validation: _emberData.default.attr("boolean"),
    starts_on: _emberData.default.attr("date"),
    total_savings: _emberData.default.attr("number", {
      readOnly: true
    }),
    translation_coupling: _emberData.default.attr("boolean"),
    translation_languages: _emberData.default.attr("string"),
    universes: _emberData.default.hasMany("universe", {
      async: false
    }),
    wants_concept: _emberData.default.attr("boolean"),
    wants_ideation: _emberData.default.attr("boolean"),
    wants_pathway: _emberData.default.attr("boolean"),
    // attributes present in light version only
    closed: _emberData.default.attr("boolean"),
    comments_count: _emberData.default.attr("number", {
      readOnly: true
    }),
    concepts: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      },
      readOnly: true
    }),
    total_active_users: _emberData.default.attr("number", {
      readOnly: true
    }),
    total_users: _emberData.default.attr("number", {
      readOnly: true
    }),
    users_count: _emberData.default.attr("number", {
      readOnly: true
    }),
    challenge_group_id: _emberData.default.attr(),
    // attributes present in full version only
    about_creator: _emberData.default.attr(),
    actions: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    active_user_modules: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    allow_nicknames: _emberData.default.attr(),
    authorized_departments: _emberData.default.attr(),
    awaiting_members: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      },
      readOnly: true
    }),
    banner_id: _emberData.default.attr(),
    banner_url: _emberData.default.attr(),
    banner_height: _emberData.default.attr("number", {
      defaultValue: 100
    }),
    badges: _emberData.default.hasMany("badge", {
      async: false
    }),
    showcase_banner_id: _emberData.default.attr(),
    showcase_banner_url: _emberData.default.attr(),
    blind_concept_user: _emberData.default.attr(),
    blind_concept: _emberData.default.attr(),
    button_id: _emberData.default.attr(),
    button_url: _emberData.default.attr(),
    challenge_comments_count: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    challenges_synchronization: _emberData.default.attr(),
    completed_ideas: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    concept_analyses: _emberData.default.attr(),
    concept_author_can_evaluate: _emberData.default.attr(),
    concept_formats: _emberData.default.attr(),
    concept_reformulated: _emberData.default.attr("boolean"),
    concept_tools: _emberData.default.attr(),
    current_user_debates_contributions: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    current_user_given_points: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    current_user_concepts_given_points: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    current_user_posted_ideas: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    current_user_score: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    current_user_juries: _emberData.default.hasMany("jury", {
      async: false
    }),
    debates: _emberData.default.hasMany("debate", {
      async: false
    }),
    default_role: _emberData.default.attr(),
    display_bubble: _emberData.default.attr(),
    display_color: _emberData.default.attr(),
    display_concept_evaluations_boxplot: _emberData.default.attr(),
    display_concept_evaluations: _emberData.default.attr(),
    display_default_badge: _emberData.default.attr(),
    display_default_list: _emberData.default.attr(),
    display_idea_evaluations_boxplot: _emberData.default.attr(),
    display_idea_evaluations: _emberData.default.attr(),
    display_likers: _emberData.default.attr(),
    display_radar_chart: _emberData.default.attr("boolean"),
    display_matrix: _emberData.default.attr("boolean"),
    display_remaining_points: _emberData.default.attr(),
    display_twitter: _emberData.default.attr(),
    display_user_score: _emberData.default.attr(),
    documents: _emberData.default.hasMany("upload", {
      async: false
    }),
    domains_cloud: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    domains: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    evaluation_min: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    evaluation_max: _emberData.default.attr("number", {
      defaultValue: 100
    }),
    evaluation_step: _emberData.default.attr("number", {
      defaultValue: 1
    }),
    current_user_evaluation_states: _emberData.default.attr(),
    evaluation_states: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    full_model: _emberData.default.attr("boolean", {
      readOnly: true
    }),
    graphic_tags_cloud: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      },
      readOnly: true
    }),
    has_ideas_to_be_evaluated: _emberData.default.attr("boolean", {
      readOnly: true
    }),
    higher_score_users: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    highest_savings: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    how_to_participate: _emberData.default.attr("string"),
    idea_author_can_evaluate: _emberData.default.attr("boolean"),
    idea_explanation: _emberData.default.attr("string"),
    idea_reformulated: _emberData.default.attr("boolean"),
    ideas: _emberData.default.attr(),
    implementation_followup: _emberData.default.attr("boolean"),
    is_template: _emberData.default.attr("boolean"),
    knowledges: _emberData.default.hasMany("knowledge", {
      async: false
    }),
    last_users_csv_export: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_users_csv_export_date: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_all_ideas_pdf_export: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_all_ideas_pdf_export_date: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_likes_csv_export: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_likes_csv_export_date: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_contributes_csv_export: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_contributes_csv_export_date: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_ideas_csv_export: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    last_ideas_csv_export_date: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    latest_comments: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    latest_concept_comments: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    latest_concept_debate_comments: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    latest_debate_comments: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    latest_ideas: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    liked_ideas: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    liker_departments: _emberData.default.attr(),
    likes_type: _emberData.default.attr(),
    logo_id: _emberData.default.attr(),
    long_description: _emberData.default.attr("string"),
    mapSettings: _emberData.default.attr(),
    members_count: _emberData.default.attr("number", {
      readOnly: true
    }),
    mirror_challenge_id: _emberData.default.attr("string"),
    source_challenge_id: _emberData.default.attr("string"),
    fullbath_challenge_name: _emberData.default.attr("string"),
    moderate_concepts: _emberData.default.attr("boolean"),
    moderate_ideas: _emberData.default.attr("boolean"),
    module_sets: _emberData.default.attr(),
    modules: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    most_active_users: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    pathways: _emberData.default.hasMany("pathway", {
      async: false
    }),
    pictures: _emberData.default.hasMany("upload", {
      async: false
    }),
    priority_concepts: _emberData.default.attr("boolean"),
    priority_ideas: _emberData.default.attr("boolean"),
    public_departments: _emberData.default.attr(),
    remaining_points_for_ideas_for_current_user: _emberData.default.attr("number", {
      readOnly: true
    }),
    replication_counters: _emberData.default.attr(),
    rules: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    score_max: _emberData.default.attr(),
    score_min: _emberData.default.attr(),
    score_q25: _emberData.default.attr(),
    score_q50: _emberData.default.attr(),
    score_q75: _emberData.default.attr(),
    selected_ideas: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    selected_total_savings: _emberData.default.attr("number", {
      readOnly: true
    }),
    state_machine_rules: _emberData.default.attr(),
    step: _emberData.default.attr(),
    surveys: _emberData.default.hasMany("survey", {
      async: false
    }),
    tags_cloud: _emberData.default.attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      },
      readOnly: true
    }),
    to_be_evaluated_ideas: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    tools: _emberData.default.attr(),
    updated_at: _emberData.default.attr(),
    use_concept_vote: _emberData.default.attr("boolean"),
    user_modules: _emberData.default.attr(undefined, {
      readOnly: true
    }),
    viral_invitation: _emberData.default.attr(),
    vote_stage: _emberData.default.attr(),
    voting_focus: _emberData.default.attr("boolean"),
    wants_ideation_or_concept: _emberData.default.attr("boolean", {
      readOnly: true
    }),
    // computed
    defaultRoleIsBlind: Ember.computed.equal("default_role", "blind"),
    topModules: Ember.computed("activeModules", function () {
      return this.activeModules.filterBy("mapping", 1).sortBy("position");
    }),
    mainModules: Ember.computed("activeModules", function () {
      return this.activeModules.filterBy("mapping", 2).sortBy("position");
    }),
    extraModules: Ember.computed("activeModules", function () {
      return this.activeModules.filterBy("mapping", 3).sortBy("position");
    }),
    leftModules: Ember.computed("activeModules", function () {
      return this.activeModules.filterBy("mapping", 4).sortBy("position");
    }),
    centerModules: Ember.computed("activeModules", function () {
      return this.activeModules.filterBy("mapping", 5).sortBy("position");
    }),
    rightModules: Ember.computed("activeModules", function () {
      return this.activeModules.filterBy("mapping", 6).sortBy("position");
    }),
    activeModules: Ember.computed("availableModules", "availableModules.@each.active", function () {
      var modules = this.availableModules || [];
      return modules.filterBy("active", true);
    }),
    masterChallenge: Ember.computed("fullbath_challenge_id", function () {
      return this.fullbath_challenge_id ? this.fullbath_challenge_id : this.id;
    }),
    availableModules: Ember.computed("modules", "wants_ideation", "wants_concept", "wants_pathway", function () {
      var stageModuleFilters = ["all"];

      if (this.wants_ideation) {
        stageModuleFilters.push("idea");
      }

      if (this.wants_concept) {
        stageModuleFilters.push("concept");
      }

      if (this.wants_pathway) {
        stageModuleFilters.push("pathway");
      }

      return this.modules.filter(function (module) {
        return stageModuleFilters.includes(module.stage);
      });
    }),
    activeIdeaModules: Ember.computed.filterBy("ideas", "active", true),
    getActiveIdeaModule: function getActiveIdeaModule(moduleName) {
      return this.activeIdeaModules.findBy("name", moduleName);
    },
    activeIdeaTools: Ember.computed("tools.[]", "tools.@each.active", function () {
      var tools = this.tools || [];
      return tools.filterBy("active", true);
    }),
    purchasingSmartIdeaModule: Ember.computed("activeIdeaModules", function () {
      return this.activeIdeaModules.findBy("name", "purchasing_smart");
    }),
    pastFutureIdeaModule: Ember.computed("activeIdeaModules", function () {
      return this.activeIdeaModules.findBy("name", "past_future");
    }),
    featuresIdeaModule: Ember.computed("activeIdeaModules", function () {
      return this.activeIdeaModules.findBy("name", "features");
    }),
    savingsIdeaModule: Ember.computed("activeIdeaModules", function () {
      return this.activeIdeaModules.findBy("name", "savings");
    }),
    featuresIdeaSelectors: Ember.computed("featuresIdeaModule", function () {
      var _this = this;

      if (!this.featuresIdeaModule) {
        return [];
      }

      var selectors = [];
      this.featuresIdeaModule.params.selectors.forEach(function (selector, index) {
        var i = 0;
        var items = Ember.A();
        items.addObject(Ember.Object.create({
          id: null,
          title: _this.localeService.t("ideas.modules.features.undefined")
        }));

        while (i < parseInt(selector)) {
          i += 1;
          items.addObject(Ember.Object.create({
            id: i.toString(),
            title: _this.localeService.t("ideas.modules.features.selector".concat(index, ".item").concat(i))
          }));
        }

        selectors.addObject(Ember.Object.create({
          id: "features_selector_" + index,
          title: _this.localeService.t("ideas.modules.features.selector".concat(index, ".title")),
          availableItems: items
        }));
      });
      return selectors;
    }),
    hasSolutionIdeaModule: Ember.computed("activeIdeaModules", function () {
      return this.activeIdeaModules.findBy("name", "solution");
    }),
    getActiveIdeaToolByName: function getActiveIdeaToolByName(toolName) {
      return this.activeIdeaTools.findBy("name", toolName);
    },
    getActiveIdeaToolById: function getActiveIdeaToolById(toolId) {
      return this.activeIdeaTools.findBy("id", toolId);
    },
    hasActiveIdeaTools: Ember.computed.notEmpty("activeIdeaTools"),
    activeConceptModules: Ember.computed.filterBy("concept_formats", "active", true),
    getActiveConceptModulesByName: function getActiveConceptModulesByName(moduleName) {
      return this.activeConceptModules.findBy("name", moduleName);
    },
    activeConceptTools: Ember.computed.filterBy("concept_tools", "active", true),
    activeSurveys: Ember.computed.filterBy("surveys", "active", true),
    lastSurvey: Ember.computed("activeSurveys.[]", "activeSurveys.@each.voted", function () {
      var firstUnvotedSurvey = this.activeSurveys.findBy("voted", false);
      return firstUnvotedSurvey !== undefined ? firstUnvotedSurvey.id : this.get("activeSurveys.firstObject.id");
    }),
    quizCompletion: Ember.computed("activeSurveys.[]", "activeSurveys.@each.voted", function () {
      return Math.round(this.activeSurveys.filterBy("voted", true).length * 100 / this.get("activeSurveys.length"));
    }),
    quizCompletionStyle: Ember.computed("quizCompletion", function () {
      return Ember.String.htmlSafe("width: ".concat(this.quizCompletion, "%"));
    }),
    stepMask: Ember.computed("step", function () {
      var mask = "000000000";
      var binary = (this.step >>> 0).toString(2);
      return (mask + binary).slice(-mask.length);
    }),
    mainDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(0) === "1";
    }),
    showcaseDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(1) === "1";
    }),
    modulesDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(2) === "1";
    }),
    gamingDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(3) === "1";
    }),
    userFormatDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(4) === "1";
    }),
    ideationFormatDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(5) === "1";
    }),
    ideationWorkflowDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(6) === "1";
    }),
    conceptFormatDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(7) === "1";
    }),
    conceptWorkflowDone: Ember.computed("stepMask", function () {
      return this.stepMask.charAt(8) === "1";
    }),
    maxStep: Ember.computed("wants_ideation", "wants_concept", "has_showcase", function () {
      var maxStep = 4;

      if (this.wants_ideation) {
        maxStep += 2;
      }

      if (this.wants_concept) {
        maxStep += 2;
      }

      if (this.has_showcase) {
        maxStep += 1;
      }

      return maxStep;
    }),
    completionPercentage: Ember.computed("maxStep", "stepMask", function () {
      var stepCount = (this.stepMask.match(/1/g) || []).length;
      return Math.round(stepCount / this.maxStep * 100);
    }),
    completionPercentageStyle: Ember.computed("completionPercentage", function () {
      return Ember.String.htmlSafe("width: ".concat(this.completionPercentage, "%"));
    }),
    logoBackground: Ember.computed("logo_url", function () {
      var url = this.logo_url;
      return Ember.String.htmlSafe(url ? "background-image: url(".concat(_configuration.default.apiUrl).concat(url, "); border: none;") : "");
    }),
    bannerBackground: Ember.computed("banner_url", function () {
      var url = this.banner_url;
      return url ? Ember.String.htmlSafe("background-image: url(".concat(_configuration.default.apiUrl).concat(url, "); border: none;")) : "";
    }),
    showcaseBannerBackground: Ember.computed("showcase_banner_url", function () {
      var url = this.showcase_banner_url;
      return url ? Ember.String.htmlSafe("background-image: url(".concat(_configuration.default.apiUrl).concat(url, "); border: none;")) : "";
    }),
    bannerStyle: Ember.computed("banner_height", function () {
      return Ember.String.htmlSafe("height: ".concat(this.banner_height * 56 / 100, "vh"));
    }),
    avatarStyle: Ember.computed("banner_height", function () {
      if (this.banner_height >= 20) {
        return Ember.String.htmlSafe("height: 186px; width: 186px;");
      }

      return Ember.String.htmlSafe("height: 100px; width: 100px; margin-top: -12px;");
    }),
    nameFor: function nameFor(element, fallback) {
      if (Ember.isBlank(this.get("dict.".concat(element)))) {
        return this.localeService.t(fallback ? fallback : element);
      }

      return this.get("dict.".concat(element));
    },
    nameForIdea: Ember.computed("dict", function () {
      return this.nameFor("idea");
    }),
    nameForAnIdea: Ember.computed("dict", function () {
      return this.nameFor("an_idea");
    }),
    nameForTheIdea: Ember.computed("dict", function () {
      return this.nameFor("the_idea");
    }),
    nameForIdeas: Ember.computed("dict", function () {
      return this.nameFor("ideas", "plural_idea");
    }),
    nameForTheIdeaAccusative: Ember.computed("dict", function () {
      var locale = this.localeService.currentLocale;
      var the_idea = this.nameFor("the_idea");

      if (locale === "de") {
        var article;

        if (Ember.isBlank(the_idea)) {
          article = "die";
        } else {
          var the_article = the_idea.split(" ")[0].toLowerCase();

          if (the_article === "der") {
            article = "den";
          } else {
            article = the_article;
          }
        }

        return "".concat(article, " ").concat(this.nameFor("idea"));
      }

      return the_idea;
    }),
    nameForAnIdeaAccusative: Ember.computed("dict", function () {
      var locale = this.localeService.currentLocale;
      var an_idea = this.nameFor("an_idea");

      if (locale === "de") {
        var article;

        if (Ember.isBlank(an_idea)) {
          article = "eine";
        } else {
          var the_article = an_idea.split(" ")[0].toLowerCase();

          if (the_article === "der") {
            article = "einen";
          } else if (the_article === "das") {
            article = "ein";
          } else {
            article = "eine";
          }
        }

        return "".concat(article, " ").concat(this.nameFor("idea"));
      }

      return this.nameFor("an_idea");
    }),
    nameForTheIdeaDative: Ember.computed("dict", function () {
      var locale = this.localeService.currentLocale;
      var the_idea = this.nameFor("the_idea");

      if (locale === "de") {
        var article;

        if (Ember.isBlank(the_idea)) {
          article = "der";
        } else {
          var the_article = the_idea.split(" ")[0].toLowerCase();

          if (the_article === "das" || the_article === "der") {
            article = "dem";
          } else {
            article = "der";
          }
        }

        return "".concat(article, " ").concat(this.nameFor("idea"));
      }

      return the_idea;
    }),
    nameForConcept: Ember.computed("dict", function () {
      return this.nameFor("concept");
    }),
    nameForAConcept: Ember.computed("dict", function () {
      return this.nameFor("a_concept");
    }),
    nameForTheConcept: Ember.computed("dict", function () {
      return this.nameFor("the_concept");
    }),
    nameForConcepts: Ember.computed("dict", function () {
      return this.nameFor("concepts", "plural_concept");
    }),
    nameForAConceptAccusative: Ember.computed("dict", function () {
      var locale = this.localeService.currentLocale;
      var a_concept = this.nameFor("a_concept");

      if (locale === "de") {
        var article;

        if (Ember.isBlank(a_concept)) {
          article = "eine";
        } else {
          var the_article = a_concept.split(" ")[0].toLowerCase();

          if (the_article === "der") {
            article = "einen";
          } else if (the_article === "das") {
            article = "ein";
          } else {
            article = "eine";
          }
        }

        return "".concat(article, " ").concat(this.nameFor("idea"));
      }

      return this.nameFor("a_concept");
    }),
    nameForTheConceptAccusative: Ember.computed("dict", function () {
      var locale = this.localeService.currentLocale;
      var the_concept = this.nameFor("the_concept");

      if (locale === "de") {
        var article;

        if (Ember.isBlank(the_concept)) {
          article = "die";
        } else {
          var the_article = the_concept.split(" ")[0].toLowerCase();

          if (the_article === "der") {
            article = "den";
          } else {
            article = the_article;
          }
        }

        return "".concat(article, " ").concat(this.nameFor("idea"));
      }

      return the_concept;
    }),
    nameForAConceptDative: Ember.computed("dict", function () {
      var locale = this.localeService.currentLocale;
      var the_concept = this.nameFor("the_concept");

      if (locale === "de") {
        var article;

        if (Ember.isBlank(the_concept)) {
          article = "einem";
        } else {
          var the_article = the_concept.split(" ")[0];

          switch (the_article.toLowerCase()) {
            case "die":
              article = "einer";
              break;

            case "das":
            case "der":
            default:
              article = "einem";
              break;
          }
        }

        return "".concat(article, " ").concat(this.nameFor("concept"));
      }

      return the_concept;
    }),
    nameForTheConceptDative: Ember.computed("dict", function () {
      var locale = this.localeService.currentLocale;
      var the_concept = this.nameFor("the_concept");

      if (locale === "de") {
        var article;

        if (Ember.isBlank(the_concept)) {
          article = "der";
        } else {
          var the_article = the_concept.split(" ")[0].toLowerCase();

          if (the_article === "das" || the_article === "der") {
            article = "dem";
          } else {
            article = "der";
          }
        }

        return "".concat(article, " ").concat(this.nameFor("concept"));
      }

      return the_concept;
    }),
    availableRoles: Ember.computed(function () {
      return [{
        id: "admin",
        name: this.localeService.t("administrator")
      }, {
        id: "moderator",
        name: this.localeService.t("moderator")
      }, {
        id: "reviewer",
        name: this.localeService.t("reviewer")
      }, {
        id: "designer",
        name: this.localeService.t("designer")
      }, {
        id: "blind",
        name: this.localeService.t("blind")
      }, {
        id: "expert",
        name: this.localeService.t("expert")
      }, {
        id: "champion",
        name: this.localeService.t("champion")
      }];
    }),
    ideasAvailableStates: Ember.computed("state_machine_rules.@each.to_name", function () {
      var _this2 = this;

      var available_states = [];
      available_states.pushObjects(this.state_machine_rules);
      available_states.pushObject(Ember.Object.create({
        id: null,
        to: "all_stages",
        to_name: this.localeService.t("all_stages"),
        moduleName: this.localeService.t("all_stages"),
        position: 0,
        selected: false
      }));
      available_states.forEach(function (state) {
        if (state.id !== null) {
          state.set("id", state.to);
          state.set("moduleName", _this2.localeService.t("from") + state.to_name);
        }

        state.set("name", state.to_name);
        state.set("selected", false);
      });
      return available_states.sortBy("position");
    }),
    uniqIdeasAvailableStates: Ember.computed("state_machine_rules.@each.to_name", function () {
      var available_states = [];
      available_states.pushObjects(this.state_machine_rules);
      available_states.forEach(function (state) {
        state.setProperties({
          id: state.to,
          name: state.to_name
        });
      });
      return available_states.sortBy("position");
    }),
    uniqIdeasAvailableStatesExceptDraft: Ember.computed("uniqIdeasAvailableStates", function () {
      var stateToRemove = this.uniqIdeasAvailableStates.findBy("id", "draft");
      return this.uniqIdeasAvailableStates.removeObject(stateToRemove);
    }),
    availableEvaluationStates: Ember.computed("uniqIdeasAvailableStates", function () {
      return [{
        id: null,
        name: ""
      }].concat((0, _toConsumableArray2.default)(this.uniqIdeasAvailableStates.filterBy("evaluation", true)));
    }),
    hasRejectedState: Ember.computed("state_machine_rules.@each.to", function () {
      return this.state_machine_rules.mapBy("to").includes("rejected");
    }),
    nameForIdeaToBeEvaluated: Ember.computed("state_machine_rules.[]", "locale", function () {
      var evaluationState = this.state_machine_rules.findBy("evaluation", true);
      return evaluationState ? evaluationState.to_name : "";
    }),
    conceptsAvailableStates: Ember.computed("priority_concepts", "concept_expert_validation", "concept_management_committee_validation", function () {
      var available_states = [Ember.Object.create({
        id: null,
        name: this.localeService.t("all_stages"),
        moduleName: this.localeService.t("all_stages"),
        position: 0,
        selected: false
      }), Ember.Object.create({
        id: "draft",
        name: this.nameFor("draft_concept"),
        moduleName: this.localeService.t("from") + this.nameFor("draft_concept"),
        position: 1,
        selected: false
      }), Ember.Object.create({
        id: "published",
        name: this.nameFor("published_concept"),
        moduleName: this.localeService.t("from") + this.nameFor("published_concept"),
        position: 2,
        selected: false
      }), Ember.Object.create({
        id: "selected",
        name: this.nameFor("selected_concept"),
        moduleName: this.localeService.t("from") + this.nameFor("selected_concept"),
        position: 9,
        selected: false
      }), Ember.Object.create({
        id: "rejected",
        name: this.nameFor("rejected_concept"),
        moduleName: this.localeService.t("from") + this.nameFor("rejected_concept"),
        position: 10,
        selected: false
      })];

      if (this.priority_concepts) {
        available_states = [].concat((0, _toConsumableArray2.default)(available_states), [Ember.Object.create({
          id: "to_be_documented",
          name: this.nameFor("to_be_documented_concept"),
          moduleName: this.localeService.t("from") + this.nameFor("to_be_documented_concept"),
          position: 3,
          selected: false
        }), Ember.Object.create({
          id: "documented",
          name: this.nameFor("documented_concept"),
          moduleName: this.localeService.t("from") + this.nameFor("documented_concept"),
          position: 4,
          selected: false
        })]);
      }

      if (this.concept_expert_validation) {
        available_states = [].concat((0, _toConsumableArray2.default)(available_states), [Ember.Object.create({
          id: "to_be_evaluated",
          name: this.nameFor("to_be_evaluated_concept"),
          moduleName: this.localeService.t("from") + this.nameFor("to_be_evaluated_concept"),
          position: 5,
          selected: false
        }), Ember.Object.create({
          id: "evaluated",
          name: this.nameFor("evaluated_concept"),
          moduleName: this.localeService.t("from") + this.nameFor("evaluated_concept"),
          position: 6,
          selected: false
        })]);
      }

      if (this.concept_management_committee_validation) {
        available_states = [].concat((0, _toConsumableArray2.default)(available_states), [Ember.Object.create({
          id: "to_be_pitched",
          name: this.nameFor("to_be_pitched_concept"),
          moduleName: this.localeService.t("from") + this.nameFor("to_be_pitched_concept"),
          position: 7,
          selected: false
        }), Ember.Object.create({
          id: "completed",
          name: this.nameFor("completed_concept"),
          moduleName: this.localeService.t("from") + this.nameFor("completed_concept"),
          position: 8,
          selected: false
        })]);
      }

      return available_states.sortBy("position");
    }),
    selectedActions: Ember.computed("wants_ideation", "wants_concept", "use_concept_vote", "actions.[]", function () {
      var vote_concept_actions = this.use_concept_vote && this.wants_concept ? this.actions.filter(function (action) {
        return ["like_concept", "liked_my_concept"].includes(action.name);
      }) : [];
      return [].concat((0, _toConsumableArray2.default)(this.actions.filter(function (action) {
        return action.stage === "all";
      })), (0, _toConsumableArray2.default)(this.wants_ideation ? this.actions.filter(function (action) {
        return action.stage === "idea";
      }) : []), (0, _toConsumableArray2.default)(this.wants_concept ? this.actions.filter(function (action) {
        return action.stage === "concept" && !vote_concept_actions.includes(action);
      }) : []), (0, _toConsumableArray2.default)(vote_concept_actions));
    }),
    selectedRules: Ember.computed("wants_ideation", "wants_concept", "use_concept_vote", "rules.[]", function () {
      return [].concat((0, _toConsumableArray2.default)(this.rules.filter(function (rule) {
        return rule.stage === "all";
      })), (0, _toConsumableArray2.default)(this.wants_ideation ? this.rules.filter(function (rule) {
        return rule.stage === "idea";
      }) : []), (0, _toConsumableArray2.default)(this.wants_concept && this.use_concept_vote ? this.rules.filter(function (rule) {
        return rule.stage === "concept";
      }) : []));
    }),
    mandatory_user_modules: Ember.computed.mapBy("active_user_modules", "user_module_id"),
    selectedUserModules: Ember.computed("mandatory_user_modules.[]", "allow_nicknames", "user_modules", function () {
      var _this3 = this;

      var modules = this.user_modules.filterBy("invisible", false);

      if (!this.allow_nicknames) {
        var nick_nameModule = this.user_modules.findBy("name", "nick_name");
        this.mandatory_user_modules.removeObject(nick_nameModule.id);
        modules = modules.rejectBy("name", "nick_name");
      }

      return modules.map(function (module) {
        return (0, _objectSpread2.default)({}, module, {
          validity: _this3.mandatory_user_modules.includes(module.id)
        });
      });
    }),
    daysLeft: Ember.computed(function () {
      var endDate = moment(this.ends_on).add(1, "days").utc().endOf("day");
      var today = moment().utc().startOf("day");
      var daysLeft = endDate.diff(today, "days");

      if (daysLeft < 0) {
        daysLeft = 0;
      }

      return daysLeft;
    }),
    timelineCompletionStyle: Ember.computed("starts_on", "ends_on", function () {
      var val;
      var starts_on = moment(this.starts_on).utc().startOf("day");
      var ends_on = moment(this.ends_on).add(1, "days").utc().endOf("day");
      var today = moment().utc();

      if (today > ends_on) {
        val = 100;
      } else if (today < starts_on) {
        val = 0;
      } else {
        var daySpent = today.diff(starts_on, "days");
        var totalDays = ends_on.diff(starts_on, "days");
        val = daySpent / totalDays * 100;
      }

      return Ember.String.htmlSafe("width:".concat(val, "%"));
    }),
    hasStarted: Ember.computed("starts_on", function () {
      return new Date(this.starts_on).getTime() < Date.now();
    }),
    isOrphan: Ember.computed("universes", function () {
      return this.universes.length === 0;
    }),
    juryWithIdeas: Ember.computed("current_user_juries.[]", function () {
      return this.current_user_juries.filter(function (j) {
        return j.total_ideas_count > 0;
      });
    }),
    // Observers
    likesTypeHaschanged: Ember.observer("likes_type", function () {
      var likes_per_idea = this.selectedRules.findBy("name", "likes_per_idea");
      var points_per_user = this.selectedRules.findBy("name", "points_per_user");

      if (likes_per_idea && points_per_user) {
        if (this.likes_type) {
          likes_per_idea.setProperties({
            active: true,
            reward: 1,
            disabled: true
          });
          points_per_user.setProperties({
            active: false,
            reward: null,
            disabled: true
          });
        } else {
          if (likes_per_idea) {
            if (typeof likes_per_idea.set === "function") {
              likes_per_idea.set("disabled", false);
            } else {
              likes_per_idea.disabled = false;
            }
          }

          if (points_per_user) {
            if (typeof points_per_user.set === "function") {
              points_per_user.set("disabled", false);
            } else {
              points_per_user.disabled = false;
            }
          }
        }
      }
    }),
    wantsIdeationDidChange: Ember.observer("wants_ideation", function () {
      if (!this.wants_ideation) {
        this.setProperties({
          display_user_score: false,
          display_remaining_points: false
        });
      }
    }),
    privateDidChange: Ember.observer("private", function () {
      if (this["private"]) {
        this.set("requires_validation", false);
      }
    }),
    updateIdeaModuleExplanation: function updateIdeaModuleExplanation(module) {
      return this._callAdapter("updateIdeaModuleExplanation", module);
    },
    updateStateInWidget: function updateStateInWidget(module, state) {
      return this._callAdapter("updateStateInWidget", module, state);
    },
    resetIdeasVotes: function resetIdeasVotes() {
      return this._callAdapter("resetIdeasVotes");
    },
    resetIdeasEvaluations: function resetIdeasEvaluations() {
      return this._callAdapter("resetIdeasEvaluations");
    },
    resetConceptsVotes: function resetConceptsVotes() {
      return this._callAdapter("resetConceptsVotes");
    },
    updateMembers: function updateMembers(user_ids, granted, no_email) {
      return this._callAdapter("updateMembers", user_ids, granted, no_email);
    },
    removeMembers: function removeMembers(user_ids) {
      return this._callAdapter("removeMembers", user_ids);
    },
    updateRoles: function updateRoles(userIds, roleIds) {
      return this._callAdapter("updateRoles", userIds, roleIds);
    },
    reorderDomains: function reorderDomains(domains) {
      return this._callAdapter("reorderDomains", domains);
    },
    emailing: function emailing(all_participants, all_users, all_authors, all_evaluators, all_incomplete_profiles, user_ids, message, title, last_ideas, last_comments, open_debates, last_concepts_comments, last_concept_debates_comments, last_debates_comments) {
      return this._callAdapter.apply(this, ["emailing"].concat(Array.prototype.slice.call(arguments)));
    },
    requestAccess: function requestAccess() {
      return this._callAdapter("requestAccess");
    },
    getHotIdeas: function getHotIdeas() {
      return this._callAdapter("getHotIdeas");
    },
    getMemberships: function getMemberships() {
      return this._callAdapter("getMemberships");
    },
    updateModuleExplanation: function updateModuleExplanation(module) {
      return this._callAdapter("updateModuleExplanation", module);
    },
    updateIdeaModuleTemplate: function updateIdeaModuleTemplate(module) {
      return this._callAdapter("updateIdeaModuleTemplate", module);
    },
    updateSupertags: function updateSupertags(id, datasetId, ideas) {
      var reset = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      return this._callAdapter("updateSupertags", id, datasetId, ideas, reset);
    },
    saveInvitation: function saveInvitation(emails, message, role) {
      return this._callAdapter.apply(this, ["saveInvitation"].concat(Array.prototype.slice.call(arguments)));
    },
    reportingKpiOverTime: function reportingKpiOverTime(dataset_id, start_date, end_date) {
      return this._callAdapter.apply(this, ["reportingKpiOverTime"].concat(Array.prototype.slice.call(arguments)));
    },
    duplicate: function duplicate() {
      return this._callAdapter("duplicate");
    },
    populate_experts: function populate_experts() {
      return this._callAdapter("populate_experts");
    },
    templatize: function templatize() {
      return this._callAdapter("templatize");
    },
    close: function close() {
      return this._callAdapter("close");
    },
    unclose: function unclose() {
      return this._callAdapter("unclose");
    },
    removeBadge: function removeBadge(userId, badge) {
      return this._callAdapter("removeBadge", userId, badge);
    },
    updateBadges: function updateBadges(userIds, badges) {
      return this._callAdapter("updateBadges", userIds, badges);
    },
    exportIdeasCSV: function exportIdeasCSV(options) {
      return this._callAdapter("exportIdeasCSV", options);
    }
  });

  _exports.default = _default;
});