define("innowaze-ember2/components/modules/past-future-module", ["exports", "ramda/src/pathOr", "innowaze-ember2/components/modules/module"], function (_exports, _pathOr, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    completedDidChange: Ember.on("init", Ember.observer("model.completion", "model.past_future_comment", function () {
      this.set("module.isNotCompleted", this.get("model.completion") === "0" || !this.get("model.past_future_comment"));
    })),
    available_typologies: Ember.computed(function () {
      return (0, _pathOr.default)([], ["params", "typology"], this.challenge.getActiveIdeaModule("past_future"));
    }),
    displayComment: Ember.computed("model.completion", function () {
      return this.get("model.completion") !== "0" && !!this.get("module.params.display_comment");
    })
  });

  _exports.default = _default;
});